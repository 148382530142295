import clsx from 'clsx';
import type { LinkProps as NextLinkProps } from 'next/link';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

import type { CSSProperties, FC, HTMLAttributeAnchorTarget, PropsWithChildren } from 'react';

import type { AnyRoute } from '@utils/route';
import { getLink } from '@utils/route';

import styles from './Link.module.scss';

export type LinkProps = PropsWithChildren<
    {
        route: AnyRoute;
        className?: string;
        classes?: Classes<'link' | 'span'>;
        target?: HTMLAttributeAnchorTarget;
        title?: string;
        style?: CSSProperties;
    } & Omit<NextLinkProps, 'href'>
>;

export const Link: FC<LinkProps> = (props) => {
    const { className, classes, target, children, route, title, style, onClick, ...otherProps } =
        props;

    const { asPath } = useRouter();
    const asPathWithoutHash = asPath.split('#')[0];
    const linkPath = typeof route === 'function' ? route() : getLink(route);

    if (asPathWithoutHash === linkPath) {
        return (
            <span
                className={clsx(
                    className,
                    classes?.span,
                    onClick !== undefined && styles.isClickable,
                )}
                onClick={onClick}
                aria-hidden="true"
            >
                {children}
            </span>
        );
    }

    return (
        <NextLink
            href={linkPath}
            className={clsx(className, classes?.link)}
            target={target}
            title={title}
            onClick={onClick}
            style={style}
            {...otherProps}
        >
            {children}
        </NextLink>
    );
};
