import type { IncomingHttpHeaders } from 'http';

import type { ApiClientClass } from '@api/client';
import { CookieAuth } from '@api/client/auth';
import { initBffApiClient } from '@api/client/bff';

export type MakeServersideApiRequestOptions<RT> = {
    request: (client: ApiClientClass) => Promise<RT>;
    headers?: IncomingHttpHeaders;
};

export const makeServerSideApiRequest = <RT>({
    request,
    headers,
}: MakeServersideApiRequestOptions<RT>): Promise<RT> =>
    request(initBffApiClient(CookieAuth(), headers));
