import { A } from '@feip-internal/fp-ts';
import { fromTraversable, Lens } from 'monocle-ts';

import type { ApiErrorResponse, ErrorMessage, PropertyError } from './types';

const errorMessageTraverse = fromTraversable(A.array)<ErrorMessage>();
const messageLens = Lens.fromProp<ErrorMessage>()('message');
const codeLens = Lens.fromProp<ErrorMessage>()('code');

const errorMessagesLensComposeToCodes = errorMessageTraverse.composeLens(codeLens).asFold();
const errorMessagesLensComposeToMessages = errorMessageTraverse.composeLens(messageLens).asFold();

type FieldError<T> = { name: T; message: string };

export class ApiError extends Error {
    private common: ErrorMessage[] | null = null;

    private invalidFields: PropertyError = {};

    constructor(
        message: string,
        url: string,
        method: string,
        status: number,
        readonly response: ApiErrorResponse,
    ) {
        super(`[ApiClient] Type: ${message}, Method: [${method}] {${status}} ${url}`);

        Object.keys(response.error).forEach((key) => {
            const error = response.error[key];
            if (error === undefined) return;
            if (key === '' && error.length) {
                this.common = error;
            } else {
                this.invalidFields[key] = error;
            }
        });
    }

    isCommon(): boolean {
        return this.common !== null;
    }

    getCommonCodes(): string[] {
        if (this.common === null) return [];
        return errorMessagesLensComposeToCodes.getAll(this.common);
    }

    getCommonMessages(): string[] {
        if (this.common === null) return [];
        return errorMessagesLensComposeToMessages.getAll(this.common);
    }

    getCommonFirstCode(): string | null {
        const codes = this.getCommonCodes();
        if (codes.length === 0) return null;
        const [first] = codes;
        if (first === undefined) return null;
        return first;
    }

    getCommonFirstMessage(): string | null {
        const messages = this.getCommonMessages();
        if (messages.length === 0) return null;
        const [first] = messages;
        if (first === undefined) return null;
        return first;
    }

    getInvalidFirstKey(): string | null {
        const keys = this.getInvalidFieldsKeys();
        if (keys.length === 0) return null;
        const [first] = keys;
        if (first === undefined) return null;
        return first;
    }

    getInvalidFirstMessage(): string | null {
        const firstKey = this.getInvalidFirstKey();
        if (firstKey === null) return null;
        const messages = this.getInvalidFieldMessages(firstKey);
        const [first] = messages;
        if (first === undefined) return null;
        return first;
    }

    hasInvalidField(key: string): boolean {
        return key in this.invalidFields;
    }

    getInvalidFieldsKeys(): string[] {
        return Object.keys(this.invalidFields);
    }

    getInvalidFieldCodes(key: string): string[] {
        const atKey = this.invalidFields[key];
        if (atKey === undefined) return [];
        return errorMessagesLensComposeToCodes.getAll(atKey);
    }

    getInvalidFieldMessages(key: string): string[] {
        const atKey = this.invalidFields[key];
        if (atKey === undefined) return [];
        return errorMessagesLensComposeToMessages.getAll(atKey);
    }

    getInvalidFields<Keys extends string>(): FieldError<Keys>[] {
        const keys = this.getInvalidFieldsKeys() as Keys[];
        return keys.map((key) => ({
            name: key,
            message: this.getInvalidFieldMessages(key).join(', '),
        }));
    }
}

export const FallbackError = (url: string, method: string, status: number): ApiError =>
    new ApiError('Fallback error', url, method, status, {
        error: {
            '': [
                {
                    code: 'invalid_response_type',
                    message: 'Ошибка обработки запроса. Повторите запрос через минуту.',
                },
            ],
        },
    });
