import { createDomain, createEvent } from 'effector';

import { Lens } from 'monocle-ts';

import type { BookingData } from '@domains/booking';

import { applyLensPayload } from '@utils/lens';
import type { AnyRoute } from '@utils/route';

export type AppState = {
    activeNavItem: AnyRoute | null;
    isFeedbackDialogOpened: boolean;
    isExcursionDialogOpened: boolean;
    checkedStories: number[];
    isSignInDialogOpened: boolean;
    isLogoutDialogOpened: boolean;
    isCatalogWidgetOpened: boolean;
    bookingDialog: {
        isOpened: boolean;
        data: BookingData | null;
    };
};
export const appDomain = createDomain();

// --- Events ---

export const clearSessionEv = createEvent({ domain: appDomain });
export const setActiveNavItem = createEvent<AnyRoute | null>({ domain: appDomain });
export const setFeedbackDialogOpened = createEvent<boolean>({ domain: appDomain });
export const setExcursionDialogOpened = createEvent<boolean>({ domain: appDomain });
export const setCheckedStories = createEvent<number[]>({ domain: appDomain });
export const setSignInDialogOpened = createEvent<boolean>({ domain: appDomain });
export const setLogoutDialogOpened = createEvent<boolean>({ domain: appDomain });
export const setCatalogWidgetOpened = createEvent<boolean>({ domain: appDomain });
export const setBookingDialogOpened = createEvent<{ isOpen: boolean; data: BookingData | null }>({
    domain: appDomain,
});

// --- Lenses ---

const appStatePropLens = Lens.fromProp<AppState>();

const appInitialState: AppState = {
    activeNavItem: null,
    isFeedbackDialogOpened: false,
    isExcursionDialogOpened: false,
    checkedStories: [],
    isSignInDialogOpened: false,
    isLogoutDialogOpened: false,
    isCatalogWidgetOpened: false,
    bookingDialog: {
        isOpened: false,
        data: null,
    },
};

export const $app = appDomain
    .createStore<AppState>(appInitialState, { name: 'app' })
    .on(setActiveNavItem, applyLensPayload(appStatePropLens('activeNavItem')))
    .on(setFeedbackDialogOpened, applyLensPayload(appStatePropLens('isFeedbackDialogOpened')))
    .on(setExcursionDialogOpened, applyLensPayload(appStatePropLens('isExcursionDialogOpened')))
    .on(setCheckedStories, applyLensPayload(appStatePropLens('checkedStories')))
    .on(setSignInDialogOpened, applyLensPayload(appStatePropLens('isSignInDialogOpened')))
    .on(setLogoutDialogOpened, applyLensPayload(appStatePropLens('isLogoutDialogOpened')))
    .on(setCatalogWidgetOpened, applyLensPayload(appStatePropLens('isCatalogWidgetOpened')))
    .on(setBookingDialogOpened, (state, { isOpen, data }) => ({
        ...state,
        bookingDialog: {
            isOpened: isOpen,
            data,
        },
    }))
    .reset(clearSessionEv);
