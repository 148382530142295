import { spread } from 'patronum';

import { useUnit } from 'effector-react';

import type { PageKind } from '@domains/global-data';

import { clearSessionEv } from '@stores/app';

import type { CommonData } from '@api/generated';

import { globalDataDomain } from './domain';
import { fetchGlobalDataFx } from './server-side';

export const defaultCommonData: CommonData = {
    header: {
        menu: [],
    },
    footer: {
        menu: [],
        projects: [],
    },
    settings: {
        seo: {
            title: null,
            description: null,
            siteTitle: null,
            image: null,
        },
        contacts: {
            whatsappUrl: null,
            telegramUrl: null,
            salesDepartmentPhone: '',
            developerOfficePhone: '',
        },
        office: {
            address: '',
            workingHours: '',
        },
        googleTagManagerId: '',
        yandexSmartCaptchaSiteKey: '',
        yandexMapsKey: '',
    },
};

export const $pageKind = globalDataDomain.createStore<PageKind>('static').reset([clearSessionEv]);

export const $commonData = globalDataDomain
    .createStore<CommonData>(defaultCommonData)
    .reset([clearSessionEv]);

export const $globalSeoData = $commonData.map(({ settings }) => settings.seo);

export const $settings = $commonData.map((data) => data.settings);

spread({
    source: fetchGlobalDataFx.doneData,
    targets: {
        commonData: $commonData,
        pageKind: $pageKind,
    },
});

export function useGlobalData() {
    return useUnit({
        commonData: $commonData,
        pageKind: $pageKind,
    });
}
export const useCommonData = (): CommonData => useUnit($commonData);
export const useSettings = () => useUnit($settings);
