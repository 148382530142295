import clsx from 'clsx';
import Head from 'next/head';
import Script from 'next/script';
import { ReactNotifications } from 'react-notifications-component';

import type { FC, PropsWithChildren } from 'react';
import { useEffect, useState } from 'react';

import { useStoreMap, useUnit } from 'effector-react';

import { S } from '@feip-internal/fp-ts';

import { pickupApartmentComparisonIds as pickupApartmentComparisonIdsEvent } from '@stores/comparison-apartments/model';
import { $commonData, $globalSeoData } from '@stores/global-data';

import type { SeoData } from '@api/generated';

import { getPreviewData } from '@utils/seo';

import { DynamicExcursionDialog } from '@components/common/ExcursionDialog/DynamicExcursionDialog';
import { Footer } from '@components/common/Footer/Footer';
import { GoogleTagManagerScript } from '@components/common/GoogleTagManagerScript/GoogleTagmanagerScript';
import { DynamicFeedbackDialog } from '@components/feedback/FeedbackDialog/DynamicFeedbackDialog';
import { Header } from '@components/header/Header/Header';

import styles from './DefaultLayout.module.scss';

const CommonComponents: FC = () => {
    const [isInitialized, setInitialized] = useState(false);

    useEffect(() => {
        setInitialized(true);
    }, []);

    /* Common components shared between projects should be placed below (modals, forms, etc..) */
    return isInitialized ? (
        <>
            <ReactNotifications />
            <DynamicFeedbackDialog />
            <DynamicExcursionDialog />
        </>
    ) : null;
};

export type DefaultLayoutProps = Readonly<{
    seoData: SeoData;
    isHeaderThin?: boolean;
}>;

export const DefaultLayout: FC<PropsWithChildren<DefaultLayoutProps>> = (props) => {
    const { children, seoData: pageSeoData, isHeaderThin = false } = props;

    const [siteSeoData, pickupApartmentComparisonIds] = useUnit([
        $globalSeoData,
        pickupApartmentComparisonIdsEvent,
    ]);

    const googleTagManagerId = useStoreMap({
        store: $commonData,
        keys: [],
        fn: ({ settings }) => settings.googleTagManagerId,
    });

    useEffect(() => {
        pickupApartmentComparisonIds();
    }, [pickupApartmentComparisonIds]);

    const isSeoDataEmpty =
        pageSeoData.title === null &&
        pageSeoData.description === null &&
        pageSeoData.keywords === null;
    const siteTitle = siteSeoData.title ?? siteSeoData.siteTitle;
    const pageTitle = pageSeoData.title;
    const hasSiteTitle = siteTitle !== null;
    const hasPageTitle = pageTitle !== null;
    let title: string | null = null;
    if (hasSiteTitle && hasPageTitle) {
        title = `${pageTitle} | ${siteTitle}`;
    } else {
        title = pageTitle ?? siteTitle ?? null;
    }
    const description = isSeoDataEmpty ? siteSeoData.description : siteSeoData.description;
    const keywords = isSeoDataEmpty ? null : pageSeoData.keywords;
    const preview = getPreviewData(siteSeoData.image);
    // const hasTitle = title !== null; // FIXME: Does not work as expected.
    const hasDescription = description !== null;
    const hasKeywords = keywords !== null;
    const hasPreview = preview !== null;
    const hasGoogleTagManagerId = !S.isEmpty(googleTagManagerId);

    return (
        <>
            <Head>
                {title !== null && <title>{title}</title>}
                {hasDescription && <meta name="description" content={description} />}
                {hasKeywords && <meta name="keywords" content={keywords} />}

                <meta name="og:type" content="website" />
                {hasSiteTitle && <meta name="og:site_name" content={siteTitle} />}
                {hasPageTitle && <meta name="og:title" content={pageTitle} />}
                {pageSeoData.description !== null && (
                    <meta name="og:description" content={pageSeoData.description} />
                )}
                {hasPreview && (
                    <>
                        <meta property="og:image" content={preview.src} />
                        <meta property="og:image:width" content={preview.width.toFixed(0)} />
                        <meta property="og:image:height" content={preview.height.toFixed(0)} />
                    </>
                )}
            </Head>

            {hasGoogleTagManagerId && <GoogleTagManagerScript id={googleTagManagerId} />}

            <div className={styles.root}>
                <header className={clsx(styles.header, !isHeaderThin && styles.isSticky)}>
                    <Header isThin={isHeaderThin} />
                </header>

                <main className={clsx(styles.body)}>{children}</main>

                <footer className={styles.footer}>
                    <Footer />
                </footer>

                <CommonComponents />

                <Script
                    src="https://cdn.callibri.ru/callibri.js"
                    type="text/javascript"
                    charSet="utf-8"
                    defer
                ></Script>
            </div>
        </>
    );
};
