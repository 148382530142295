import { YMapComponentsProvider } from 'ymap3-components';

import type { FC, PropsWithChildren } from 'react';
import { createContext } from 'react';

import { useSettings } from '@stores/global-data';

export type YMapsProviderContext = {
    ymapsKey?: string;
};

export const yMapsDefaultData: YMapsProviderContext = {
    ymapsKey: '',
};

export const YMapsContext = createContext<YMapsProviderContext>(yMapsDefaultData);
export const YMapsProvider: FC<PropsWithChildren<YMapsProviderContext>> = (props) => {
    const { children } = props;
    const { yandexMapsKey } = useSettings();

    return (
        <YMapsContext.Provider value={{ ymapsKey: yandexMapsKey }}>
            <YMapComponentsProvider apiKey={yandexMapsKey}>{children}</YMapComponentsProvider>
        </YMapsContext.Provider>
    );
};
