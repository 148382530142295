import clsx from 'clsx';

import type { FC, ReactNode } from 'react';

import { useUnit } from 'effector-react';

import { setFeedbackDialogOpened as setFeedbackDialogOpenedEvent } from '@stores/app';
import { useCommonData, useSettings } from '@stores/global-data';

import { GtmEventKind, sendGtmEvent } from '@utils/analytics';
import { isValueSet } from '@utils/guards';
import { formatRussianPhoneNumber } from '@utils/phone-number';
import type { AnyRoute } from '@utils/route';
import { makeContactsPageRoute } from '@utils/route';
import {
    EulaRoute,
    makeHomeRoute,
    makeProjectRoute,
    makeProjectsRoute,
    makeSearchRoute,
    PrivacyPolicyRoute,
} from '@utils/route';

import { Accordion } from '@components/common/Accordion/Accordion';
import { Button, ButtonLink } from '@components/common/Button/Button';
import { Container } from '@components/common/Container/Container';
import { Link } from '@components/common/Link/Link';
import { SubmitWidget } from '@components/common/SubmitWidget/SubmitWidget';

import styles from './Footer.module.scss';

import { TelegramIcon, WhatsappIcon } from '@assets/icons';
import { FeipLogoImage, LogoSmallImage } from '@assets/images';

const accordionTitle = (title: ReactNode, route: AnyRoute, action?: () => void) => (
    <>
        <Link route={route} className={styles.accordionTitle} onClick={action}>
            {title}
        </Link>
        <span className={clsx(styles.accordionTitle, styles.isMediumDown)}>{title}</span>
    </>
);

export const Footer: FC = () => {
    const {
        footer: { menu, projects },
    } = useCommonData();
    const {
        contacts: { telegramUrl, whatsappUrl, salesDepartmentPhone, developerOfficePhone },
        office: { address, workingHours },
    } = useSettings();
    const setFeedbackDialogOpened = useUnit(setFeedbackDialogOpenedEvent);
    const onConsultButtonClick = () => {
        setFeedbackDialogOpened(true);
        sendGtmEvent({ kind: GtmEventKind.ConsultationButtonClicked });
    };

    return (
        <div className={styles.root}>
            <SubmitWidget />

            <div className={styles.mainFooter}>
                <Container classes={{ root: styles.container }}>
                    <div className={styles.middleRow}>
                        <div className={styles.columns}>
                            <div className={styles.column}>
                                <Accordion
                                    title={accordionTitle(
                                        <LogoSmallImage className={styles.tgLogo} />,
                                        makeHomeRoute(),
                                    )}
                                    classes={{
                                        root: styles.accordion,
                                        button: styles.accordionButton,
                                        icon: styles.accordionIcon,
                                        contentContainer: styles.accordionContentContainer,
                                    }}
                                >
                                    <div className={styles.links}>
                                        {menu.map(({ id, url, title, shouldOpenInNewTab }) => (
                                            <Link
                                                key={id}
                                                route={url}
                                                target={shouldOpenInNewTab ? '_blank' : '_self'}
                                                className={styles.link}
                                            >
                                                {title}
                                            </Link>
                                        ))}
                                    </div>
                                </Accordion>
                            </div>
                            <div className={styles.column}>
                                <Accordion
                                    title={accordionTitle('Проекты', makeProjectsRoute(), () => {
                                        sendGtmEvent({ kind: GtmEventKind.ProjectsButtonClicked });
                                    })}
                                    classes={{
                                        root: styles.accordion,
                                        button: styles.accordionButton,
                                        icon: styles.accordionIcon,
                                        contentContainer: styles.accordionContentContainer,
                                    }}
                                >
                                    <div className={styles.links}>
                                        {projects.map((item, index) => (
                                            <Link
                                                key={index}
                                                route={makeProjectRoute(item.slug)}
                                                className={styles.link}
                                            >
                                                {item.title}
                                            </Link>
                                        ))}
                                    </div>
                                </Accordion>
                            </div>
                            <div className={styles.column}>
                                <Accordion
                                    title={accordionTitle(
                                        'Контакты',
                                        makeContactsPageRoute(),
                                        () => {
                                            sendGtmEvent({
                                                kind: GtmEventKind.NavigatedToContacts,
                                            });
                                        },
                                    )}
                                    classes={{
                                        root: styles.accordion,
                                        button: styles.accordionButton,
                                        icon: styles.accordionIcon,
                                        contentContainer: styles.accordionContentContainer,
                                    }}
                                >
                                    <div className={styles.links}>
                                        {isValueSet(salesDepartmentPhone) && (
                                            <div className={styles.phoneLinkBlock}>
                                                <Link
                                                    route={`tel:+${salesDepartmentPhone}`}
                                                    className={styles.phoneLink}
                                                    target="_blank"
                                                    onClick={() => {
                                                        sendGtmEvent({
                                                            kind: GtmEventKind.PhoneNumberClicked,
                                                        });
                                                    }}
                                                >
                                                    {formatRussianPhoneNumber(salesDepartmentPhone)}
                                                </Link>
                                                <p className={styles.phoneLinkDescription}>
                                                    Отдел продаж
                                                </p>
                                            </div>
                                        )}

                                        {isValueSet(developerOfficePhone) && (
                                            <div className={styles.phoneLinkBlock}>
                                                <Link
                                                    route={`tel:+${developerOfficePhone}`}
                                                    className={styles.phoneLink}
                                                    target="_blank"
                                                    onClick={() => {
                                                        sendGtmEvent({
                                                            kind: GtmEventKind.PhoneNumberClicked,
                                                        });
                                                    }}
                                                >
                                                    {formatRussianPhoneNumber(developerOfficePhone)}
                                                </Link>
                                                <p className={styles.phoneLinkDescription}>
                                                    Офис застройщика
                                                </p>
                                            </div>
                                        )}

                                        {address !== null && (
                                            <address className={styles.address}>{address}</address>
                                        )}

                                        {workingHours !== null && (
                                            <p className={styles.time}>{workingHours}</p>
                                        )}

                                        {whatsappUrl !== null && (
                                            <Link
                                                className={styles.socialLink}
                                                route={whatsappUrl}
                                                target="_blank"
                                                onClick={() => {
                                                    sendGtmEvent({
                                                        kind: GtmEventKind.MessengerLinkClicked,
                                                    });
                                                }}
                                            >
                                                <WhatsappIcon className={styles.whatsappIcon} />
                                                Мы в WhatsApp
                                            </Link>
                                        )}

                                        {telegramUrl !== null && (
                                            <Link
                                                className={styles.socialLink}
                                                route={telegramUrl}
                                                target="_blank"
                                                onClick={() => {
                                                    sendGtmEvent({
                                                        kind: GtmEventKind.MessengerLinkClicked,
                                                    });
                                                }}
                                            >
                                                <TelegramIcon className={styles.telegramIcon} />
                                                Мы в Telegram
                                            </Link>
                                        )}
                                    </div>
                                </Accordion>
                            </div>
                        </div>
                        <div className={styles.buttons}>
                            <ButtonLink
                                href={makeSearchRoute()}
                                variant="transparentOutline"
                                isFullWidth
                                onClick={() => {
                                    sendGtmEvent({ kind: GtmEventKind.SearchButtonClicked });
                                }}
                            >
                                Выбрать квартиру
                            </ButtonLink>

                            <Button
                                variant="transparentOutlineWhite"
                                onClick={() => {
                                    onConsultButtonClick();
                                }}
                                isFullWidth
                                className={styles.consultButton}
                            >
                                Консультация
                            </Button>
                        </div>
                    </div>
                    <div className={styles.bottomRow}>
                        <div className={styles.privacyLinks}>
                            <Link className={styles.privacyLink} route={PrivacyPolicyRoute}>
                                Политика конфиденциальности
                            </Link>
                            <Link className={styles.eulaLink} route={EulaRoute}>
                                Пользовательское соглашение
                            </Link>
                        </div>

                        <Link route="https://feip.co/" target="blank">
                            <FeipLogoImage className={styles.feipLogo} />
                        </Link>
                    </div>
                </Container>
            </div>
        </div>
    );
};
