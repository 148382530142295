import { useUnit } from 'effector-react';

import { setFeedbackDialogOpened } from '@stores/app';

import { GtmEventKind, sendGtmEvent } from '@utils/analytics';
import {
    isSameRoute,
    makeContactsPageRoute,
    makeProjectsRoute,
    makeSearchRoute,
} from '@utils/route';

export const useHeader = () => {
    const $setFeedbackDialogOpened = useUnit(setFeedbackDialogOpened);

    const consultButtonClick = () => {
        $setFeedbackDialogOpened(true);
        sendGtmEvent({ kind: GtmEventKind.ConsultationButtonClicked });
    };

    const menuItemClick = (route: string) => {
        if (isSameRoute(route, makeProjectsRoute())) {
            sendGtmEvent({ kind: GtmEventKind.ProjectsButtonClicked });
        } else if (isSameRoute(route, makeSearchRoute())) {
            sendGtmEvent({ kind: GtmEventKind.SearchButtonClicked });
        } else if (isSameRoute(route, makeContactsPageRoute())) {
            sendGtmEvent({ kind: GtmEventKind.NavigatedToContacts });
        }
    };

    return {
        consultButtonClick,
        menuItemClick,
    };
};
