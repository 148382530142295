import clsx from 'clsx';

import type { FC, PropsWithChildren } from 'react';

import styles from './Container.module.scss';

type ClassKey = 'root';
type ContainerProps = {
    classes?: Classes<ClassKey>;
    size?: 'default' | 'small' | 'expand';
};
export const Container: FC<PropsWithChildren<ContainerProps>> = (props) => {
    const { classes, children, size = 'default' } = props;

    return <div className={clsx(styles.root, styles[size], classes?.root)}>{children}</div>;
};
