import * as Tooltip from '@radix-ui/react-tooltip';
import type { AppType } from 'next/app';
import Router from 'next/router';
import 'nprogress/nprogress.css';
import { debug } from 'patronum';
import 'react-notifications-component/dist/scss/notification.scss';
import SmoothScrollPolyfill from 'smoothscroll-polyfill';

import { useEffect } from 'react';

import { EffectorNext, getClientScope } from '@effector/next';

import type { PageProps } from '@domains/global-data';

import { progressDone, progressStart } from '@utils/nprogress';

import { YMapsProvider } from '@providers/YMapsProvider';

import { ErrorBoundary } from '@components/common/ErrorBoundary/ErrorBoundary';

import '@styles/global.scss';
import '@styles/vendor/nprogress/nprogress.scss';
import '@styles/vendor/react-notification-manager/react-notification-manager.scss';

const clientScope = getClientScope();

if (process.env.NODE_ENV === 'development' && clientScope !== null) {
    debug.registerScope(clientScope, { name: 'tmga-client-scope' });
}

const GlobalApp: AppType<PageProps> = (props) => {
    const {
        Component,
        pageProps: { pageProps, serializedScope },
    } = props;

    useEffect(() => {
        SmoothScrollPolyfill.polyfill();

        if (typeof window !== 'undefined') {
            Router.events.on('routeChangeStart', progressStart);
            Router.events.on('routeChangeComplete', progressDone);
            Router.events.on('routeChangeError', progressDone);
        }

        return () => {
            if (typeof window !== 'undefined') {
                Router.events.off('routeChangeStart', progressStart);
                Router.events.off('routeChangeComplete', progressDone);
                Router.events.off('routeChangeError', progressDone);
            }
        };
    }, []);

    return (
        <ErrorBoundary>
            <EffectorNext values={serializedScope}>
                <Tooltip.Provider delayDuration={300}>
                    <YMapsProvider>
                        <Component {...pageProps} />
                    </YMapsProvider>
                </Tooltip.Provider>
            </EffectorNext>
        </ErrorBoundary>
    );
};

export default GlobalApp;
