import { globalDataDomain } from '@stores/global-data/domain';

import type { CommonDataPayload, CommonDataResult } from '@api/protocol';

import { makeServerSideApiRequest } from '@utils/server-side';

import type { FetchGlobalDataFxPayload, FetchGlobalDataFxResult } from './types';

export const fetchGlobalDataFx = globalDataDomain.createEffect<
    FetchGlobalDataFxPayload,
    FetchGlobalDataFxResult
>(async ({ pageKind, headers }) =>
    makeServerSideApiRequest<FetchGlobalDataFxResult>({
        headers,
        request: async (client) => {
            const commonData = await client.get<CommonDataResult, CommonDataPayload>(
                '/api/common_data',
            );

            return { pageKind, commonData };
        },
    }),
);
