import clsx from 'clsx';
import { useRouter } from 'next/router';

import type { FC, ReactNode } from 'react';

import { getLink, Routes } from '@utils/route';

import type { LinkProps } from '@components/common/Link/Link';
import { Link } from '@components/common/Link/Link';

import styles from './HeaderNavItem.module.scss';

type HeaderNavItemProps = LinkProps &
    Partial<{
        isActive: boolean;
        isActiveIndicatorDisabled: boolean;
        iconBefore?: ReactNode;
        iconAfter?: ReactNode;
    }>;
export const HeaderNavItem: FC<HeaderNavItemProps> = (props) => {
    const {
        route,
        classes,
        className,
        isActive = false,
        isActiveIndicatorDisabled = false,
        iconBefore,
        iconAfter,
        children,
        ...rest
    } = props;

    const { asPath } = useRouter();
    const asPathWithoutHash = asPath.split('#')[0];
    const linkPath = typeof route === 'function' ? route() : getLink(route);
    const commonClassName = clsx(styles.root, className);

    // TODO FIX: temporary workaround. Implement $activeNavItem correctly
    if (
        asPath !== Routes.Home &&
        !linkPath.includes(`${Routes.Projects}/`) &&
        (asPathWithoutHash === linkPath || linkPath.includes(asPathWithoutHash ?? ''))
    ) {
        return (
            <span className={clsx(commonClassName, !isActiveIndicatorDisabled && styles.isActive)}>
                {iconBefore}
                <span className={clsx(styles.text, classes?.span)}>{children}</span>
                {iconAfter}
            </span>
        );
    }

    return (
        <Link
            route={linkPath}
            className={commonClassName}
            classes={{
                link: clsx(
                    styles.isLink,
                    isActive && !isActiveIndicatorDisabled && styles.isActive,
                    classes?.link,
                ),
            }}
            {...rest}
        >
            {iconBefore}
            <span className={clsx(styles.text, classes?.span)}>{children}</span>
            {iconAfter}
        </Link>
    );
};
