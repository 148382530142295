import type { AxiosRequestConfig } from 'axios';
import axios, { isAxiosError } from 'axios';
import qs from 'qs';

import * as SafeBase64 from '@utils/base64';
import { COMPRESS_GET_QUERY_PARAMS } from '@utils/env';

import type { Authorization } from './auth';
import { ApiError, FallbackError } from './errors';
import type {
    CaptureContext,
    CaptureExceptionSignature,
    RequestContext,
    RequestPayload,
} from './types';
import { apiErrorResponseSchema, HttpMethod } from './types';

export class ApiClientClass {
    private defaultHeaders = {
        Accept: 'application/json',
        Platform: 'Web',
        'Platform-Image-Formats': 'svg,avif,webp,png,jpeg',
    };

    private unthrottledToken: string | null = null;

    private sentryHandler: CaptureExceptionSignature | null = null;

    private backendEndpoint: string | null = null;

    private invalidTokenHandler: () => void = () => {};

    constructor(private readonly auth: Authorization) {}

    public setSentryHandler(handler: CaptureExceptionSignature): void {
        this.sentryHandler = handler;
    }

    public setBackendEndpoint(endpoint: string): void {
        this.backendEndpoint = endpoint;
    }

    public setInvalidTokenHandler(handler: () => void): void {
        this.invalidTokenHandler = handler;
    }

    public setUnthrottledToken(token: string): void {
        this.unthrottledToken = token;
    }

    public setCustomHeaders(headers: Record<string, unknown>): void {
        this.defaultHeaders = {
            ...this.defaultHeaders,
            ...headers,
        };
    }

    public get<Response, Request extends RequestPayload = null>(
        url: string,
        payload?: Request,
    ): Promise<Response> {
        return this.request<Response>(HttpMethod.Get, url, payload);
    }

    public post<Response, Request extends RequestPayload = null>(
        url: string,
        payload?: Request,
    ): Promise<Response> {
        return this.request<Response>(HttpMethod.Post, url, payload, {
            'Content-Type': 'application/json',
        });
    }

    private request<T>(
        method: HttpMethod,
        path: string,
        payload: RequestPayload,
        headers = {},
    ): Promise<T> {
        const requestHeaders: Record<string, string> = {
            ...this.defaultHeaders,
            ...headers,
            ...this.auth.getAuthHeaders(),
        };

        if (this.unthrottledToken !== null) {
            requestHeaders['X-Unthrottled-Authorization'] = `Bearer ${this.unthrottledToken}`;
        }

        const fetchPayload: AxiosRequestConfig = {
            headers: requestHeaders,
            withCredentials: true,
            data: payload,
            method,
        };

        if (payload !== null && payload !== undefined) {
            switch (method) {
                case HttpMethod.Post:
                    fetchPayload.data = payload;
                    break;
                case HttpMethod.Get:
                    if (Object.keys(payload).length > 0) {
                        fetchPayload.data = undefined;
                        if (COMPRESS_GET_QUERY_PARAMS) {
                            fetchPayload.params = {
                                _body: SafeBase64.encode(JSON.stringify(payload)),
                            };
                        } else {
                            fetchPayload.params = payload;
                            fetchPayload.paramsSerializer = (params) =>
                                qs.stringify(params, { arrayFormat: 'comma' });
                        }
                    }
            }
        }

        const url = this.backendEndpoint !== null ? `${this.backendEndpoint}${path}` : path;

        const request: RequestContext = { url, method, payload, headers: requestHeaders };

        return axios(url, fetchPayload)
            .then((response) => {
                const contentType: unknown = response.headers['content-type'];
                if (typeof contentType !== 'string' || !contentType.includes('application/json')) {
                    this.captureExceptionAndThrow(
                        FallbackError(request.url, request.method, response.status),
                        {
                            extra: { request, response: response.data },
                        },
                    );
                }

                return response.data as T;
            })
            .catch((error: unknown) => {
                if (isAxiosError(error)) {
                    const captureContext: CaptureContext = {
                        extra: { request, response: error.response },
                    };

                    const parsedError = apiErrorResponseSchema.safeParse(error.response?.data);

                    if (
                        error.response?.status === 422 &&
                        'error' in error.response.data &&
                        parsedError.success
                    ) {
                        const ServerError = new ApiError(
                            'Backend sanitize error',
                            request.url,
                            request.method,
                            error.response.status,
                            parsedError.data,
                        );

                        if (ServerError.getCommonCodes().includes('invalid_token')) {
                            this.invalidTokenHandler();
                        }

                        throw ServerError;
                    }

                    if (typeof error.response?.status === 'number') {
                        this.captureExceptionAndThrow(
                            FallbackError(request.url, request.method, error.response.status),
                            captureContext,
                        );
                    }
                }

                const message = error instanceof Error ? error.message : 'Unexpected error';

                this.captureExceptionAndThrow(FallbackError(request.url, request.method, 500), {
                    extra: { request, message },
                });
            });
    }

    private captureException(error: ApiError, context: CaptureContext): void {
        if (this.sentryHandler !== null) this.sentryHandler(error, context);
    }

    private captureExceptionAndThrow(error: ApiError, context: CaptureContext): never {
        this.captureException(error, context);
        throw error;
    }
}
