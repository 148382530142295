import type { Duration } from 'date-fns';
import {
    add,
    differenceInSeconds,
    format,
    getDay,
    getYear,
    isToday,
    isTomorrow,
    isYesterday,
    parse,
} from 'date-fns';
import { ru } from 'date-fns/locale';

import { WeekdaysEnum } from '@api/generated';

const formatDate = (date: Date) => {
    let dateFormat = 'dd MMMM yyyy';
    if (isYesterday(date)) return 'Вчера';
    if (isToday(date)) return 'Сегодня';
    if (isTomorrow(date)) return 'Завтра';
    if (getYear(date) === getYear(new Date())) dateFormat = 'dd MMMM';
    return format(date, dateFormat, { locale: ru });
};

export const formatApiDate = (apiDate: FeipApi.Date): string => {
    const date = parse(apiDate, 'yyyy.MM.dd', new Date());
    return formatDate(date);
};

export const parseApiDateToDate = (date: FeipApi.Date) => parse(date, 'yyyy.MM.dd', new Date());

export const getApiDateFromDate = (date: Date): FeipApi.Date =>
    format(date, 'yyyy.MM.dd') as FeipApi.Date;

export const formatApiDateTime = (apiDate: FeipApi.DateTime): string =>
    formatDate(new Date(apiDate));

export const convertMinutestToSeconds = (minutes: number): number => minutes * 60;

export const filterDate = (dates: number[]) => (date: Date) => {
    const day = getDay(date);
    return dates.includes(day);
};

export const WeekdaysMap: Record<WeekdaysEnum, number> = {
    [WeekdaysEnum.Sunday]: 0,
    [WeekdaysEnum.Monday]: 1,
    [WeekdaysEnum.Tuesday]: 2,
    [WeekdaysEnum.Wednesday]: 3,
    [WeekdaysEnum.Thursday]: 4,
    [WeekdaysEnum.Friday]: 5,
    [WeekdaysEnum.Saturday]: 6,
};

// Accepts only natural numbers (not floats)
export const makeSeconds = (duration: Duration): number => {
    const now = new Date();
    return differenceInSeconds(add(now, duration), now);
};
