declare const integerNumberSymbol: unique symbol;

export type IntegerNumber = Opaque<number, typeof integerNumberSymbol>;

export const formatFloat = (
    value: string | number,
    digits = 2,
    separator = ' ',
    removeTrailingZeros = true,
): string => {
    const val = parseFloat(String(value));
    const formatted = val
        .toFixed(digits)
        .replace(/(\d)(?=(\d{3})+(\.|$))/g, `$1${separator}`)
        .replace(/\./g, ',');
    return formatted.endsWith('00') && removeTrailingZeros
        ? formatted.replace(/,\d{2}/, '')
        : formatted;
};

export const onlyNumbers = (value: string): string => value.replace(/\D/g, '');

export const parseNumberFromString = (input: string, fallback = 0): number => {
    const parsedNumber = parseInt(input);
    return isNaN(parsedNumber) ? fallback : parsedNumber;
};

export const clamp =
    (min: number, max: number) =>
    (value: number): number =>
        Math.min(Math.max(value, min), max);
