import type { ImageSet, ImageUrl, SeoData } from '@api/generated';
import { ImageFormat } from '@api/generated';

export type PreviewData = Readonly<{ src: string; width: number; height: number }>;

export const emptySeoData: SeoData = { title: null, description: null, keywords: null };

const getImageUrlPriority = (imageUrl: ImageUrl) => {
    switch (imageUrl.format) {
        case ImageFormat.Svg:
            return 0;
        case ImageFormat.Png:
            return 2;
        case ImageFormat.Jpeg:
            return 3;
        case ImageFormat.Webp:
            return 4;
        case ImageFormat.Avif:
            return 5;
        default:
            return 6;
    }
};

export const getPreviewData = (imageSet: ImageSet | null): PreviewData | null => {
    if (imageSet === null) {
        return null;
    }

    const { width, height, urls } = imageSet.web.small[1];
    const sortedUrls = [...urls].sort((a, b) => getImageUrlPriority(a) - getImageUrlPriority(b));
    const src = sortedUrls[0]?.url;

    if (src === undefined) {
        return null;
    }

    return { width, height, src };
};
