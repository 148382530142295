import { atob, btoa } from 'abab';
import { decode as safeDecode, encode as safeEncode } from 'url-safe-base64';

import { F } from '@feip-internal/fp-ts';

const throwableAtoB = F.flow(atob, (res) => {
    if (res === null) throw new Error('The string to be encoded is not correctly decoded.');
    return res;
});

const throwableBtoA = F.flow(btoa, (res) => {
    if (res === null) throw new Error('The string to be decoded is not correctly encoded.');
    return res;
});

const encodeBase64IntoSafe = (s: string): string =>
    s
        .split('')
        .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('');

const decodeBase64IntoSafe = (s: string): string =>
    s.replace(/%([0-9A-F]{2})/g, (match, p1: string) =>
        String.fromCharCode(parseInt('0x' + p1, 16)),
    );

const removeBase64Appendix = (s: string): string => s.replace(/\.{0,2}/g, '');

const decode = F.flow(safeDecode, throwableAtoB, encodeBase64IntoSafe, decodeURIComponent);
const encode = F.flow(
    encodeURIComponent,
    decodeBase64IntoSafe,
    throwableBtoA,
    safeEncode,
    removeBase64Appendix,
);

export { encode, decode };
