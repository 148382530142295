import { onlyNumbers } from '@utils/number';

export const formatRussianPhoneNumber = (phoneNumber: string) => {
    const cleanedPhoneNumber = onlyNumbers(phoneNumber);
    const code = cleanedPhoneNumber.substring(0, 1);

    if (cleanedPhoneNumber.length !== 11 || (code !== '7' && code !== '8')) return phoneNumber;

    const countryCode = '+7';
    const areaCode = cleanedPhoneNumber.substring(1, 4);
    const blockOfThreeDigits = cleanedPhoneNumber.substring(4, 7);
    const firstBlockOfTwoDigits = cleanedPhoneNumber.substring(7, 9);
    const secondBlockOfTwoDigits = cleanedPhoneNumber.substring(9, 11);

    return `${countryCode} (${areaCode}) ${blockOfThreeDigits}-${firstBlockOfTwoDigits}-${secondBlockOfTwoDigits}`;
};
