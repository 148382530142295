import Typograf from 'typograf';
import type rulesJSON from 'typograf/dist/typograf.titles.json';

// add string type to not break enableByMask method
type TypografRule = keyof typeof rulesJSON;

const rules: TypografRule[] = ['ru/money/currency'];

const instance = new Typograf({ locale: ['ru'], enableRule: rules });

export const applyTypograf = (text: string, disableRules: TypografRule[] = []): string => {
    if (disableRules.length > 0) {
        disableRules.forEach((rule) => {
            instance.disableRule(rule);
        });
    }
    return instance.execute(text);
};
