import type { AuthorizationHeader, Token, TokenGetter, TokenSetter } from './types';
import type { AuthorizationType } from './types';

export class Authorization {
    constructor(
        private token: Token,
        readonly use: boolean,
        readonly authType: AuthorizationType | null,
        private tokenGetter: TokenGetter,
        private tokenSetter: TokenSetter,
    ) {}

    getAuthHeaders(): AuthorizationHeader {
        const token = this.tokenGetter();
        let authHeaders: AuthorizationHeader = {};
        if (this.use && token !== null) {
            authHeaders = { Authorization: `${this.authType} ${token}` };
        }
        return authHeaders;
    }

    setTokenGetter(tokenGetter: TokenGetter): void {
        this.tokenGetter = tokenGetter;
    }

    setTokenSetter(tokenSetter: TokenSetter): void {
        this.tokenSetter = tokenSetter;
    }

    setToken(token: Token): void {
        this.token = token;
        this.tokenSetter(token);
    }
}

export const CookieAuth = (): Authorization =>
    new Authorization(
        null,
        false,
        null,
        () => null,
        () => {},
    );
