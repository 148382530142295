import { makeSeconds } from '@utils/date';

const defaultISRRevalidateInSeconds = makeSeconds({ minutes: 5 });

export const ISRRevalidateConfigInSeconds = {
    Home: makeSeconds({ minutes: 3 }),
    Projects: defaultISRRevalidateInSeconds,
    About: defaultISRRevalidateInSeconds,
    Requisites: defaultISRRevalidateInSeconds,
    Project: defaultISRRevalidateInSeconds,
    ProjectApartments: defaultISRRevalidateInSeconds,
    ProjectApartment: defaultISRRevalidateInSeconds,
    ProjectDocuments: defaultISRRevalidateInSeconds,
    ProjectStorerooms: defaultISRRevalidateInSeconds,
    Render: defaultISRRevalidateInSeconds,
    Error: defaultISRRevalidateInSeconds,
    NotFound: defaultISRRevalidateInSeconds,
    Page: defaultISRRevalidateInSeconds,
};

export const regexRules = {
    RuPhone: /^(?:\+7|7|8)\s?\((\d{3})\)\s?(\d{3})[-\s]?(\d{2})[-\s]?(\d{2})/i,
    Email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};

export const paginationDefaultOptions = {
    isFirstAndLastAlwaysVisible: true,
    extremePagesLimit: 1,
    nearbyPagesLimit: 2,
    nearbySmallPagesLimit: 1,
};

export const EXCURSION_AVAILABLE_WEEK_DAYS = [3, 6]; // Wednesday, Saturday

export const DIALOG_ANIMATION_DURATION = 400;
