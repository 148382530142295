import clsx from 'clsx';

import type { FC, ReactNode } from 'react';
import { useState } from 'react';

import styles from './Accordion.module.scss';

import { ChevronBottomSmallIcon } from '@assets/icons';

export const Accordion: FC<{
    title: ReactNode;
    children: ReactNode;
    icon?: SvgIcon;
    className?: string;
    classes?: Classes<'root' | 'button' | 'buttonInner' | 'icon' | 'contentContainer' | 'content'>;
    defaultExpanded?: boolean;
}> = ({
    title,
    children,
    className,
    classes,
    icon: AccordionIcon = ChevronBottomSmallIcon,
    defaultExpanded = false,
}) => {
    const [isExpanded, setIsExpanded] = useState(defaultExpanded);

    return (
        <div className={clsx(className, classes?.root)}>
            <button
                type="button"
                onClick={() => {
                    setIsExpanded((prevIsExpanded) => !prevIsExpanded);
                }}
                className={clsx(styles.button, classes?.button)}
            >
                <div className={clsx(styles.buttonInner, classes?.buttonInner)}>
                    <span>{title}</span>
                    <AccordionIcon
                        className={clsx(
                            styles.arrowIcon,
                            isExpanded && styles.isRotated,
                            classes?.icon,
                        )}
                    />
                </div>
            </button>

            <div
                className={clsx(
                    styles.contentContainer,
                    isExpanded && styles.isExpanded,
                    classes?.contentContainer,
                )}
            >
                <div className={clsx(styles.content, classes?.content)}>{children}</div>
            </div>
        </div>
    );
};
