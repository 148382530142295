import type { IncomingHttpHeaders } from 'http';
import type { GetServerSideProps, GetStaticProps, GetStaticPropsResult, PreviewData } from 'next';
import type { ParsedUrlQuery } from 'querystring';

import { R } from '@feip-internal/fp-ts';

import type { PageProps } from '@domains/global-data';

import type { Authorization } from '@api/client/auth';
import { ApiError } from '@api/client/errors';
import { ApiClientClass } from '@api/client/index';

import { ISRRevalidateConfigInSeconds } from '@utils/constants';
import { BACKEND_ENDPOINT, UNTHROTTLED_TOKEN } from '@utils/env';
import { proxyHeaders } from '@utils/network';
import { Routes } from '@utils/route';
import { trackException } from '@utils/sentry';

export const initBffApiClient = (auth: Authorization, headers?: IncomingHttpHeaders) => {
    const backendEndpoint = BACKEND_ENDPOINT;
    const unthrottledToken = UNTHROTTLED_TOKEN;

    const ApiClient = new ApiClientClass(auth);

    ApiClient.setSentryHandler(trackException);

    if (backendEndpoint !== undefined) {
        ApiClient.setBackendEndpoint(backendEndpoint);
    }

    if (unthrottledToken !== undefined) {
        ApiClient.setUnthrottledToken(unthrottledToken);
    }

    if (headers !== undefined && !R.isEmpty(headers)) {
        ApiClient.setCustomHeaders(proxyHeaders(headers));
    }

    return ApiClient;
};

export const getServerSidePropsWithErrorHandling =
    <
        Props extends PageProps = PageProps,
        Params extends ParsedUrlQuery = ParsedUrlQuery,
        Preview extends PreviewData = PreviewData,
    >(
        getServerSideProps: GetServerSideProps<Props, Params, Preview>,
        shouldShow404OnError = process.env.NODE_ENV !== 'development',
    ) =>
    async (
        ...args: Parameters<GetServerSideProps<Props, Params, Preview>>
    ): ReturnType<GetServerSideProps<Props, Params, Preview>> => {
        try {
            return await getServerSideProps(...args);
        } catch (error) {
            if (error instanceof ApiError && error.getCommonCodes().includes('invalid_token')) {
                return { redirect: { destination: Routes.Home, permanent: false } };
            }

            trackException(error);

            if (shouldShow404OnError) {
                return { notFound: true };
            }

            throw error;
        }
    };

export const getStaticPropsWithErrorHandling =
    <
        Props extends PageProps = PageProps,
        Params extends ParsedUrlQuery = ParsedUrlQuery,
        Preview extends PreviewData = PreviewData,
    >(
        getServerSideProps: GetStaticProps<Props, Params, Preview>,
        shouldShow404OnError: 'redirect' | 'show' | 'not-show' = 'show',
    ) =>
    async (
        ...args: Parameters<GetServerSideProps<Props, Params, Preview>>
    ): Promise<GetStaticPropsResult<Props>> => {
        try {
            return await getServerSideProps(...args);
        } catch (error) {
            if (error instanceof ApiError && error.getCommonCodes().includes('invalid_token')) {
                return {
                    redirect: { destination: Routes.Home, permanent: false },
                    revalidate: ISRRevalidateConfigInSeconds.NotFound,
                };
            }

            trackException(error);

            if (shouldShow404OnError === 'show') {
                return {
                    notFound: true,
                    revalidate: ISRRevalidateConfigInSeconds.NotFound,
                };
            }

            if (shouldShow404OnError === 'redirect') {
                return {
                    redirect: { destination: Routes.NotFound, permanent: false },
                    revalidate: ISRRevalidateConfigInSeconds.NotFound,
                };
            }

            throw error;
        }
    };
