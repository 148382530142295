import type { FC } from 'react';

import { useUnit } from 'effector-react';

import { $comparisonApartmentsIds } from '@stores/comparison-apartments/model';
import { useSettings } from '@stores/global-data';

import type { MenuItem } from '@api/generated';

import { GtmEventKind, sendGtmEvent } from '@utils/analytics';
import { isValueSet } from '@utils/guards';
import { formatRussianPhoneNumber } from '@utils/phone-number';
import { makeCompareApartmentsRoute } from '@utils/route';

import { Link } from '@components/common/Link/Link';
import { useHeader } from '@components/header/use-header';

import styles from './MobileMenu.module.scss';

import { BarChartIcon, CloseIcon, MessageIcon } from '@assets/icons';

export const MobileMenu: FC<{ menu: MenuItem[]; onClose: () => void }> = ({ menu, onClose }) => {
    const {
        contacts: { salesDepartmentPhone, developerOfficePhone },
    } = useSettings();

    const { consultButtonClick, menuItemClick } = useHeader();

    const comparisonApartmentsIds = useUnit($comparisonApartmentsIds);

    return (
        <div className={styles.root}>
            <button type="button" onClick={onClose} className={styles.closeButton}>
                <CloseIcon className={styles.closeIcon} />
            </button>

            <div className={styles.content}>
                <div className={styles.phones}>
                    {isValueSet(salesDepartmentPhone) && (
                        <div className={styles.phoneLinkBlock}>
                            <Link
                                className={styles.phoneLink}
                                route={`tel:+${salesDepartmentPhone}`}
                                onClick={() => {
                                    sendGtmEvent({ kind: GtmEventKind.PhoneNumberClicked });
                                }}
                            >
                                {formatRussianPhoneNumber(salesDepartmentPhone)}
                            </Link>
                            <p className={styles.phoneLinkDescription}>Отдел продаж</p>
                        </div>
                    )}
                    {isValueSet(developerOfficePhone) && (
                        <div className={styles.phoneLinkBlock}>
                            <Link
                                className={styles.phoneLink}
                                route={`tel:+${developerOfficePhone}`}
                                onClick={() => {
                                    sendGtmEvent({ kind: GtmEventKind.PhoneNumberClicked });
                                }}
                            >
                                {formatRussianPhoneNumber(developerOfficePhone)}
                            </Link>
                            <p className={styles.phoneLinkDescription}>Офис застройщика</p>
                        </div>
                    )}
                </div>

                <nav className={styles.links}>
                    {menu.map(({ id, title, url, shouldOpenInNewTab }) => (
                        <Link
                            key={id}
                            route={url}
                            title={title}
                            target={shouldOpenInNewTab ? '_blank' : '_self'}
                            className={styles.item}
                            onClick={() => {
                                menuItemClick(url);
                            }}
                        >
                            {title}
                        </Link>
                    ))}
                </nav>

                <div className={styles.bottomSection}>
                    <Link
                        route={makeCompareApartmentsRoute()}
                        className={styles.navItemWithIcon}
                        onClick={() => {
                            sendGtmEvent({ kind: GtmEventKind.CompareApartmentsButtonClicked });
                        }}
                    >
                        <div className={styles.barChartIconContainer}>
                            <BarChartIcon className={styles.barChartIcon} />
                            {comparisonApartmentsIds.length > 0 && (
                                <span className={styles.badge}>
                                    {comparisonApartmentsIds.length}
                                </span>
                            )}
                        </div>
                        Сравнить квартиры
                    </Link>

                    <button
                        type="button"
                        aria-label="Консультация"
                        className={styles.navItemWithIcon}
                        onClick={consultButtonClick}
                    >
                        <MessageIcon className={styles.messageIcon} /> Консультация
                    </button>
                </div>
            </div>
        </div>
    );
};
