import { trackException } from './sentry';

export enum GtmEventKind {
    PhoneNumberClicked = 'phone_number_clicked',
    MessengerLinkClicked = 'messenger_link_clicked',
    SearchButtonClicked = 'search_button_clicked',
    ConsultationButtonClicked = 'consultation_button_clicked',
    ConsultationFormSubmitted = 'consultation_form_submitted',
    NavigatedToApartments = 'navigated_to_apartments',
    ProjectsButtonClicked = 'projects_button_clicked',
    CompareApartmentsButtonClicked = 'compare_apartments_button_clicked',
    StoryOpened = 'story_opened',
    FiltersButtonClicked = 'filters_button_clicked',
    SearchByParametersButtonClicked = 'search_by_parameters_button_clicked',
    TwoGisButtonClicked = 'two_gis_button_clicked',
    ToFavoritesButtonClicked = 'to_favorites_button_clicked',
    NavigatedToStorerooms = 'navigated_to_storerooms',
    NavigatedToParkingSpots = 'navigated_to_parking_spots',
    BookedStoreroomFormSubmitted = 'booked_storeroom_form_submitted',
    BookedApartmentFormSubmitted = 'booked_apartment_form_submitted',
    BookedParkingSpotFormSubmitted = 'booked_parking_spot_form_submitted',
    NavigatedToContacts = 'navigated_to_contacts',
}

export type GtmEventBase<T extends GtmEventKind> = Readonly<{ kind: T }>;

export type GtmEvent = GtmEventBase<GtmEventKind>;

type DataLayerEventObject = {
    event?: GtmEventKind;
    eventCallback?: () => void;
};

declare global {
    interface Window {
        dataLayer?: DataLayerEventObject[];
    }
}

export const sendGtmEvent = (event: GtmEvent): void => {
    const promise = new Promise<void>((resolve, reject) => {
        if ('dataLayer' in window && window.dataLayer !== undefined) {
            const { kind: eventKind, ...eventOptions } = event;
            const timeoutHandle = setTimeout(() => {
                reject(new Error(`Timeout sending analytics event: ${JSON.stringify(event)}`));
            }, 3500);
            window.dataLayer.push({
                event: eventKind,
                eventCallback: () => {
                    clearTimeout(timeoutHandle);
                    resolve();
                },
                ...eventOptions,
            });
        } else {
            reject(new Error("Can't send analytics event, `window.dataLayer` is not defined"));
        }
    });
    promise.catch(trackException);
};
