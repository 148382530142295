import { F, Ord, R } from '@feip-internal/fp-ts';

import { APP_URL } from '@utils/env';

export const Routes = {
    Home: '/',
    About: '/about',
    Contacts: '/contacts',
    Search: '/search',
    Requisites: '/requisites',
    StaticPage: '/:slug',
    Projects: '/projects',
    Project: '/projects/:slug',
    Documents: '/projects/:slug/documents',
    BuildingRender: '/projects/:slug/building',
    ApartmentRender: '/projects/:slug/apartments',
    Apartment: '/projects/:projectSlug/apartments/:apartmentSlug',
    Storerooms: '/projects/:slug/storerooms',
    ParkingSpots: '/projects/:slug/parking-spots',
    ProjectFloorPlan: '/projects/:slug/floorPlan',
    CompareApartments: '/compare',
    NotFound: '/404',
} satisfies Record<string, string>;

export type AnyRoute = (() => string) | string;

export type KnownRoute = keyof typeof Routes;

type MakeRouteOptions = Partial<{
    params: Record<string, string | number>;
    query: Record<string, string | number | undefined>;
}>;
type MakeRoute = (route: KnownRoute, options?: MakeRouteOptions) => string;
export const makeRoute: MakeRoute = (route, options = {}) => {
    const { params = {}, query } = options;
    const stringRoute = Routes[route].toString();
    const url = F.pipe(
        params,
        R.reduceWithIndex(Ord.fromCompare(() => 1))(stringRoute, (queryParam, result, value) =>
            result.replace(new RegExp(`:${queryParam}`, 'g'), value.toString()),
        ),
    );
    if (query === undefined) return url;
    const urlSearchParams = Object.keys(query).reduce<Record<string, string>>((acc, key) => {
        const value = query[key];
        if (value !== undefined) acc[key] = value.toString();
        return acc;
    }, {});
    const searchParams = new URLSearchParams(urlSearchParams).toString();
    return `${url}?${searchParams}`;
};

export const makeHomeRoute = () => makeRoute('Home');
export const makeAboutPageRoute = () => makeRoute('About');
export const makeContactsPageRoute = () => makeRoute('Contacts');
export const makeProjectsRoute = () => makeRoute('Projects');
export const makeProjectRoute = (slug: string, blockId?: string) => {
    const route = makeRoute('Project', { params: { slug } });
    return blockId !== undefined ? `${route}#${blockId}` : route;
};
export const makeSearchRoute = () => makeRoute('Search');
export const makeRequisitesRoute = () => makeRoute('Requisites');
export const makeDocumentsRoute = (slug: string) => makeRoute('Documents', { params: { slug } });
export const makeStaticPageRoute = (slug: string) => makeRoute('StaticPage', { params: { slug } });
export const makeApartmentRenderPageRoute = (slug: string) =>
    makeRoute('ApartmentRender', { params: { slug } });
export const makeApartmentPageRoute = (projectSlug: string, apartmentSlug: string) =>
    makeRoute('Apartment', { params: { projectSlug, apartmentSlug } });
export const makeStoreroomsRoute = (slug: string) => makeRoute('Storerooms', { params: { slug } });
export const makeParkingSpotsRoute = (slug: string) =>
    makeRoute('ParkingSpots', { params: { slug } });
export const NotFound = () => makeRoute('NotFound');
export const makeCompareApartmentsRoute = () => makeRoute('CompareApartments');

export const EulaRoute = makeStaticPageRoute('eula');
export const PrivacyPolicyRoute = makeStaticPageRoute('privacy');
export const makeApartmentsPlanRoute = (slug: string) =>
    makeRoute('ApartmentRender', { params: { slug } });
export const makeBuildingPlanRoute = (slug: string) =>
    makeRoute('BuildingRender', { params: { slug } });

export const getLink = (route: string) => (R.has(route, Routes) ? makeRoute(route) : route);

export const isSameRoute = (raw: string, knownRoute: string) => {
    return raw.startsWith(knownRoute) || raw.startsWith(String(APP_URL) + knownRoute);
};
