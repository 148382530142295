import { comparisonApartments } from '@stores/comparison-apartments/domian';

import type { CompareApartmentsPayload, CompareApartmentsResult } from '@api/protocol';

import { makeServerSideApiRequest } from '@utils/server-side';

export const fetchComparisonApartmentsFx = comparisonApartments.createEffect<
    CompareApartmentsPayload,
    CompareApartmentsResult
>(async (payload) =>
    makeServerSideApiRequest<CompareApartmentsResult>({
        request: async (client) => {
            if (payload.apartmentIds.length === 0) return { items: [] };
            return client.get<CompareApartmentsResult, CompareApartmentsPayload>(
                '/api/compare_apartments',
                payload,
            );
        },
    }),
);
