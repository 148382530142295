import type { Ref, RefObject } from 'react';

export const isValueSet = <T>(value?: T | null): value is T =>
    value !== null && value !== undefined;

export const isNotEmpty = <T extends string | Array<unknown>>(value?: T | null): value is T =>
    isValueSet<T>(value) && value.length > 0;

export const isString = (value: unknown): value is string => typeof value === 'string';
export const isUndefined = (value: unknown): value is undefined => typeof value === 'undefined';
export const isEmptyString = (value: unknown): boolean => isString(value) && value.length === 0;

export const isStringHasAboveChars =
    (len: number) =>
    (value: unknown): boolean =>
        isString(value) && value.length >= len;

export const isStringHasBelowChars =
    (len: number) =>
    (value: unknown): value is string =>
        isString(value) && value.length <= len;

export const hasProps = <T>(value: { props: T }): value is { props: T } =>
    typeof value === 'object' && 'props' in value;

export const refGuard = <T>(ref: Ref<T> | undefined, pass: (refObject: RefObject<T>) => void) => {
    if (isValueSet(ref) && 'current' in ref) pass(ref);
};
