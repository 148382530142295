import type * as Dialog from '@radix-ui/react-dialog';
import type { PortalProps } from '@radix-ui/react-dialog';
import { Content, Overlay, Portal, Root } from '@radix-ui/react-dialog';
import { animated, easings, useTransition } from '@react-spring/web';
import clsx from 'clsx';

import type { CSSProperties, FC, ReactNode } from 'react';

import styles from './Drawer.module.scss';

type OnOpenAutoFocusHandler = Dialog.DialogContentProps['onOpenAutoFocus'];
type ClassKey = 'root' | 'drawerContent' | 'overlay';
type DrawerContentClassKey = 'isOpen';

export type DrawerProps = {
    isOpen: boolean;
    children: ReactNode;
    onChangeOpen?: (isOpen: boolean) => void;
    classes?: Classes<ClassKey>;
    drawerContentClasses?: Classes<DrawerContentClassKey>;
    portalProps?: PortalProps;
    hasCancelAutoFocus?: boolean;
};
export const Drawer: FC<DrawerProps> = (props) => {
    const {
        isOpen,
        onChangeOpen,
        classes,
        drawerContentClasses,
        children,
        portalProps,
        hasCancelAutoFocus = false,
    } = props;

    const AnimatedDialogOverlay = animated(Overlay);
    const AnimatedDialogContent = animated(Content);

    const transitions = useTransition(isOpen, {
        from: { opacity: 0, translate: 100 },
        enter: { opacity: 1, translate: 0 },
        leave: { opacity: 0, translate: 100 },
        config: {
            duration: 500 /* ms */,
            easing: easings.easeInOutQuart,
        },
    });

    const handleOpenAutoFocus: OnOpenAutoFocusHandler = (event) => {
        if (hasCancelAutoFocus) {
            event.preventDefault();
        }
    };

    return transitions((transitionStyles, item) => (
        <Root open={item} onOpenChange={onChangeOpen}>
            <Portal {...portalProps}>
                <AnimatedDialogOverlay
                    className={clsx(styles.overlay, classes?.overlay)}
                    style={
                        {
                            backgroundColor: transitionStyles.opacity.to(
                                (value) => `rgba(72, 58, 64 ${value * 0.65})`,
                            ),
                            opacity: transitionStyles.opacity,
                        } as CSSProperties
                    }
                >
                    <AnimatedDialogContent
                        aria-label="Меню"
                        className={clsx(
                            styles.drawerContent,
                            isOpen && drawerContentClasses?.isOpen,
                            classes?.drawerContent,
                        )}
                        style={{
                            transform: transitionStyles.translate.to(
                                (value) => `translateX(${value}%)`,
                            ),
                        }}
                        onOpenAutoFocus={handleOpenAutoFocus}
                    >
                        {children}
                    </AnimatedDialogContent>
                </AnimatedDialogOverlay>
            </Portal>
        </Root>
    ));
};
