import type { BookApartmentPayload } from '@domains/apartment';
import type { ProjectParkingSpotsBookPayload } from '@domains/parking-spots';
import type { ProjectStoreroomsBookPayload } from '@domains/storerooms';

import type { RequestPayload } from '@api/client/types';
import type {
    ApartmentBase,
    CompareApartmentsListParams,
    ExcursionFormPayload,
} from '@api/generated';

import { trackException } from '@utils/sentry';

import { ApiClientClass } from './client';
import { CookieAuth } from './client/auth';
import type {
    BookApartmentRequest,
    BookApartmentResponse,
    CommonDataPayload,
    CommonDataResult,
    FeedbackAppealSubmitPayload,
    FeedbackAppealSubmitResult,
    MortgagePayload,
    MortgageResult,
    ProjectParkingSpotsBookRequest,
    ProjectParkingSpotsBookResponse,
    ProjectParkingSpotsPlanRequest,
    ProjectParkingSpotsPlanResponse,
    ProjectsRequestExcursionSubmitRequest,
    ProjectsRequestExcursionSubmitResponse,
    ProjectsRequestExcursionWeekdaysRequest,
    ProjectsRequestExcursionWeekdaysResponse,
    ProjectStoreroomsBookRequest,
    ProjectStoreroomsBookResponse,
    ProjectStoreroomsPlanRequest,
    ProjectStoreroomsPlanResponse,
    RefineComparisonApartmentsIdsRequest,
    RefineComparisonApartmentsIdsResponse,
    RequestConsultationSubmitRequest,
    RequestConsultationSubmitResponse,
    SearchPayload,
    SearchResponse,
} from './protocol';

const ApiClient = new ApiClientClass(CookieAuth());
ApiClient.setSentryHandler(trackException);

const makeApiRequest =
    <Request extends RequestPayload, Response = void>(method: 'get' | 'post', url: string) =>
    (payload?: Request | Record<string | number | symbol, never>): Promise<Response> => {
        if (method === 'post') return ApiClient.post<Response, Request>(url, payload);
        return ApiClient.get<Response, Request>(url, payload);
    };

export const apiFeedbackAppealSubmit = makeApiRequest<
    FeedbackAppealSubmitPayload,
    FeedbackAppealSubmitResult
>('post', '/api/feedback/appeal/submit');

export const apiGetCommonData = makeApiRequest<CommonDataPayload, CommonDataResult>(
    'get',
    '/api/common_data',
);

export const apiRequestConsultationSubmit = makeApiRequest<
    RequestConsultationSubmitRequest,
    RequestConsultationSubmitResponse
>('post', '/api/request/consultation/submit');

export const apiProjectsRequestExcursionWeekdays = (projectSlug: string) =>
    makeApiRequest<
        ProjectsRequestExcursionWeekdaysRequest,
        ProjectsRequestExcursionWeekdaysResponse
    >('get', `/api/projects/${projectSlug}/excursion/weekdays`)();

export const apiProjectsRequestExcursionSubmit = (
    payload: ExcursionFormPayload,
    projectSlug: string,
) =>
    makeApiRequest<ProjectsRequestExcursionSubmitRequest, ProjectsRequestExcursionSubmitResponse>(
        'post',
        `/api/projects/${projectSlug}/excursion/submit`,
    )(payload);

export const apiBookApartmentSubmit = (
    payload: BookApartmentPayload,
    projectSlug: string,
    apartmentId: ApartmentBase['id'],
) =>
    makeApiRequest<BookApartmentRequest, BookApartmentResponse>(
        'post',
        `/api/projects/${projectSlug}/apartments/${apartmentId}/book`,
    )(payload);

export const apiGetSearchResults = makeApiRequest<SearchPayload, SearchResponse>(
    'get',
    '/api/search',
);

export const apiBookStoreroom = (
    payload: ProjectStoreroomsBookPayload,
    projectSlug: string,
    storeRoomId: number,
) =>
    makeApiRequest<ProjectStoreroomsBookRequest, ProjectStoreroomsBookResponse>(
        'post',
        `/api/projects/${projectSlug}/storeroom/${storeRoomId}/book`,
    )(payload);

export const apiBookParkingSpot = (
    payload: ProjectParkingSpotsBookPayload,
    projectSlug: string,
    parkingSpotId: number,
) =>
    makeApiRequest<ProjectParkingSpotsBookRequest, ProjectParkingSpotsBookResponse>(
        'post',
        `/api/projects/${projectSlug}/parking_spot/${parkingSpotId}/book`,
    )(payload);

export const apiGetMortgage = (
    payload: Omit<MortgagePayload, 'apartmentSlug'>,
    apartmentSlug: ApartmentBase['slug'],
) =>
    makeApiRequest<Omit<MortgagePayload, 'apartmentSlug'>, MortgageResult>(
        'get',
        `/api/mortgage/${apartmentSlug}`,
    )(payload);

export const apiRefineComparisonApartmentIds = (payload: CompareApartmentsListParams) =>
    makeApiRequest<RefineComparisonApartmentsIdsRequest, RefineComparisonApartmentsIdsResponse>(
        'get',
        '/api/refine_comparison_apartment_ids',
    )(payload);

export const apiGetProjectStoreroomsFloorPlan = (
    projectSlug: string,
    payload: Omit<ProjectStoreroomsPlanRequest, 'projectSlug'>,
) =>
    makeApiRequest<
        Omit<ProjectStoreroomsPlanRequest, 'projectSlug'>,
        ProjectStoreroomsPlanResponse
    >(
        'get',
        `/api/projects/${projectSlug}/storerooms_plan`,
    )(payload);

export const apiGetProjectParkingSpotsFloorPlan = (
    projectSlug: string,
    payload: Omit<ProjectParkingSpotsPlanRequest, 'projectSlug'>,
) =>
    makeApiRequest<
        Omit<ProjectParkingSpotsPlanRequest, 'projectSlug'>,
        ProjectParkingSpotsPlanResponse
    >(
        'get',
        `/api/projects/${projectSlug}/parking_spots_plan`,
    )(payload);
