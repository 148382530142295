import clsx from 'clsx';

import type { FC } from 'react';
import { useState } from 'react';

import { useCommonData } from '@stores/global-data';

import { GtmEventKind, sendGtmEvent } from '@utils/analytics';
import { isValueSet } from '@utils/guards';
import { formatRussianPhoneNumber } from '@utils/phone-number';
import { makeCompareApartmentsRoute, makeHomeRoute } from '@utils/route';

import { Container } from '@components/common/Container/Container';
import { Drawer } from '@components/common/Drawer/Drawer';
import { Link } from '@components/common/Link/Link';
import { HeaderNavItem } from '@components/header/HeaderNavItem/HeaderNavItem';
import { MobileMenu } from '@components/header/MobileMenu/MobileMenu';
import { useHeader } from '@components/header/use-header';

import styles from './Header.module.scss';

import { BarChartIcon, BurgerIcon, MessageIcon, PhoneIcon } from '@assets/icons';
import { LogoImageImage, LogoSmallImage } from '@assets/images';

type HeaderProps = {
    isThin?: boolean;
};

export const Header: FC<HeaderProps> = (props) => {
    const { isThin = false } = props;
    const {
        header: { menu },
        settings: {
            contacts: { salesDepartmentPhone },
        },
    } = useCommonData();

    const [isDrawerOpened, setIsDrawerOpened] = useState(false);

    const { consultButtonClick, menuItemClick } = useHeader();

    return (
        <div className={clsx(styles.root, isThin && styles.isThin)}>
            <Container classes={{ root: styles.container }}>
                <div className={styles.leftHalf}>
                    <Link route={makeHomeRoute()} className={styles.logo}>
                        <LogoSmallImage
                            className={clsx(styles.logoSmallIcon, isThin && styles.isThin)}
                        />
                    </Link>

                    <nav className={styles.nav}>
                        {menu.map(({ title, url, id, shouldOpenInNewTab }) => (
                            <HeaderNavItem
                                route={url}
                                key={id}
                                target={shouldOpenInNewTab ? '_blank' : '_self'}
                                onClick={() => {
                                    menuItemClick(url);
                                }}
                            >
                                {title}
                            </HeaderNavItem>
                        ))}
                    </nav>
                </div>

                <div className={styles.rightHalf}>
                    <HeaderNavItem
                        route={makeCompareApartmentsRoute()}
                        title="Сравнить квартиры"
                        classes={{ span: styles.navItemText }}
                        iconBefore={
                            <div className={styles.barChartWrapper}>
                                <BarChartIcon className={styles.iconInner} />
                            </div>
                        }
                        onClick={() => {
                            sendGtmEvent({ kind: GtmEventKind.CompareApartmentsButtonClicked });
                        }}
                    >
                        Сравнить квартиры
                    </HeaderNavItem>
                    <button
                        type="button"
                        title="Консультация"
                        aria-label="Консультация"
                        className={styles.navItemWithIcon}
                        onClick={consultButtonClick}
                    >
                        <div className={styles.messageWrapper}>
                            <MessageIcon className={styles.iconInner} />
                        </div>{' '}
                        <span className={styles.consultation}>Консультация</span>
                    </button>

                    {isValueSet(salesDepartmentPhone) && (
                        <Link
                            className={styles.phoneLink}
                            route={`tel:+${salesDepartmentPhone}`}
                            onClick={() => {
                                sendGtmEvent({
                                    kind: GtmEventKind.PhoneNumberClicked,
                                });
                            }}
                        >
                            <div className={styles.phoneWrapper}>
                                <PhoneIcon className={styles.iconInner} />
                            </div>
                            <div className={styles.phone}>
                                <span className={styles.phoneDescription}>Отдел продаж</span>
                                {formatRussianPhoneNumber(salesDepartmentPhone)}
                            </div>
                        </Link>
                    )}

                    <LogoImageImage className={styles.logoImage} />

                    <button
                        type="button"
                        className={styles.burgerButton}
                        title="Меню"
                        aria-label="Меню"
                        onClick={() => {
                            setIsDrawerOpened(true);
                        }}
                    >
                        <BurgerIcon />
                    </button>
                </div>
            </Container>

            <Drawer
                isOpen={isDrawerOpened}
                onChangeOpen={() => {
                    setIsDrawerOpened(false);
                }}
                classes={{
                    drawerContent: styles.drawerContent,
                    overlay: styles.drawerOverlay,
                }}
                drawerContentClasses={{
                    isOpen: styles.drawerContentOpen,
                }}
            >
                <MobileMenu
                    {...{ menu }}
                    onClose={() => {
                        setIsDrawerOpened(false);
                    }}
                />
            </Drawer>
        </div>
    );
};
