export { default as Logo2GisIcon } from './2gis-logo.svg';
export { default as ArrowNextIcon } from './arrow-next.svg';
export { default as ArrowPrevIcon } from './arrow-prev.svg';
export { default as ArrowsPinchIcon } from './arrows-pinch.svg';
export { default as ArrowsSpreadIcon } from './arrows-spread.svg';
export { default as BarChartIcon } from './bar-chart.svg';
export { default as BurgerIcon } from './burger.svg';
export { default as CalendarIcon } from './calendar.svg';
export { default as CarIcon } from './car.svg';
export { default as CheckCircleIcon } from './check-circle.svg';
export { default as CheckIcon } from './check-icon.svg';
export { default as ChevronBottomSmallIcon } from './chevron-bottom-small.svg';
export { default as ChevronDownIcon } from './chevron-down.svg';
export { default as ChevronLeftIcon } from './chevron-left.svg';
export { default as ChevronRightIcon } from './chevron-right.svg';
export { default as ChevronRightBoldIcon } from './chevron-right-bold.svg';
export { default as ChevronUpIcon } from './chevron-up.svg';
export { default as CloseIcon } from './close.svg';
export { default as CompassIcon } from './compass.svg';
export { default as CopyIcon } from './copy.svg';
export { default as CrossIcon } from './cross.svg';
export { default as DeleteOutlineIcon } from './delete-outline.svg';
export { default as GearIcon } from './gear.svg';
export { default as LocationPinIcon } from './location-pin.svg';
export { default as MapMarker } from './map-marker.svg';
export { default as MessageIcon } from './message.svg';
export { default as PdfIcon } from './pdf.svg';
export { default as PhoneIcon } from './phone.svg';
export { default as TelegramIcon } from './telegram.svg';
export { default as TickInCircleIcon } from './tick-in-circle.svg';
export { default as WhatsappIcon } from './whatsapp.svg';
export { default as ZoomIcon } from './zoom.svg';
export { default as MagnifyingGlassIcon } from './magnifying-glass.svg';
