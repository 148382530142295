import clsx from 'clsx';
import Link from 'next/link';

import type {
    ComponentPropsWithoutRef,
    ComponentPropsWithRef,
    FC,
    ForwardRefExoticComponent,
    HTMLAttributeAnchorTarget,
    PropsWithoutRef,
    RefAttributes,
} from 'react';
import { forwardRef } from 'react';

import type { ClassNameMap } from '@domains/shared';

import { Spinner } from '@components/common/Spinner/Spinner';

import styles from './Button.module.scss';

export type ButtonVariant =
    | 'darkBlue'
    | 'transparentOutline'
    | 'transparentOutlineWhite'
    | 'gray'
    | 'hoverDarkBlue';

export type ButtonSize = 'small' | 'normal' | 'medium';

const variantStyles: ClassNameMap<ButtonVariant> = {
    darkBlue: styles.darkBlueVariant,
    transparentOutline: styles.transparentOutlineVariant,
    transparentOutlineWhite: styles.transparentOutlineWhiteVariant,
    gray: styles.grayVariant,
    hoverDarkBlue: styles.whiteHoverDarkblueVariant,
};

const sizeToClassMap: Record<ButtonSize, string | undefined> = {
    small: styles.smallSize,
    medium: styles.mediumSize,
    normal: undefined,
};

export type ButtonProps = Readonly<
    ComponentPropsWithRef<'button'> & {
        variant: ButtonVariant;
        size?: ButtonSize;
        iconBefore?: FC<ComponentPropsWithoutRef<'svg'>>;
        iconAfter?: FC<ComponentPropsWithoutRef<'svg'>>;
        className?: string;
        classes?: Classes<'root' | 'iconBefore' | 'content' | 'iconAfter'>;
        isLoading?: boolean;
        isLargeRounded?: boolean;
        isFullWidth?: boolean;
    }
>;

export const Button: ForwardRefExoticComponent<
    PropsWithoutRef<ButtonProps> & RefAttributes<HTMLButtonElement>
> = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    const {
        variant,
        iconBefore: IconBefore,
        iconAfter: IconAfter,
        className,
        classes,
        children,
        size,
        disabled = false,
        isLoading = false,
        isLargeRounded = false,
        isFullWidth = false,
        ...restProps
    } = props;

    const hasIconBefore = IconBefore !== undefined;
    const hasIconAfter = IconAfter !== undefined;
    const composedClassName = clsx(
        styles.root,
        className,
        classes?.root,
        size !== undefined ? sizeToClassMap[size] : null,
        variantStyles[variant],
        isLargeRounded && styles.isRounded,
        isFullWidth && styles.fullWidth,
    );

    return (
        <button
            ref={ref}
            type="button"
            className={composedClassName}
            disabled={disabled || isLoading}
            {...restProps}
        >
            {hasIconBefore && (
                <IconBefore
                    className={clsx(
                        styles.icon,
                        classes?.iconBefore,
                        children === undefined ? styles.isOnly : styles.isBefore,
                    )}
                />
            )}
            {children !== undefined && (
                <span
                    className={clsx(
                        styles.children,
                        isLoading && styles.isLoading,
                        classes?.content,
                    )}
                >
                    {children}
                </span>
            )}
            {hasIconAfter && (
                <IconAfter className={clsx(styles.icon, styles.isAfter, classes?.iconAfter)} />
            )}
            <Spinner
                size="default"
                classes={{ root: clsx(styles.spinner, isLoading && styles.isLoading) }}
            />
        </button>
    );
});

Button.displayName = 'Button';

export type ButtonLinkProps = Readonly<
    ComponentPropsWithoutRef<typeof Link> & {
        variant: ButtonVariant;
        size?: ButtonSize;
        iconBefore?: FC<ComponentPropsWithoutRef<'svg'>>;
        iconAfter?: FC<ComponentPropsWithoutRef<'svg'>>;
        className?: string;
        classes?: Classes<'root' | 'iconBefore' | 'content' | 'iconAfter'>;
        isLargeRounded?: boolean;
        isFullWidth?: boolean;
        linkTarget?: HTMLAttributeAnchorTarget;
        isLinkDisabled?: boolean;
    }
>;

export const ButtonLink: FC<ButtonLinkProps> = forwardRef<HTMLAnchorElement, ButtonLinkProps>(
    (props, ref) => {
        const {
            variant,
            iconBefore: IconBefore,
            iconAfter: IconAfter,
            className,
            classes,
            children,
            size,
            isLargeRounded = false,
            isFullWidth = false,
            linkTarget,
            isLinkDisabled = false,
            ...restProps
        } = props;

        const hasIconBefore = IconBefore !== undefined;
        const hasIconAfter = IconAfter !== undefined;
        const composedClassName = clsx(
            styles.root,
            className,
            classes?.root,
            variantStyles[variant],
            size !== undefined ? sizeToClassMap[size] : null,
            isLargeRounded && styles.isRounded,
            isFullWidth && styles.fullWidth,
            isLinkDisabled && styles.isLinkDisabled,
        );

        return (
            <Link ref={ref} target={linkTarget} className={composedClassName} {...restProps}>
                {hasIconBefore && (
                    <IconBefore
                        className={clsx(styles.icon, styles.isBefore, classes?.iconBefore)}
                    />
                )}
                {children !== undefined && (
                    <span className={clsx(styles.children, classes?.content)}>{children}</span>
                )}
                {hasIconAfter && (
                    <IconAfter className={clsx(styles.icon, styles.isAfter, classes?.iconAfter)} />
                )}
            </Link>
        );
    },
);

ButtonLink.displayName = 'ButtonLink';

export type IconButtonProps = Readonly<
    Omit<ComponentPropsWithoutRef<'button'>, 'children'> & {
        variant: ButtonVariant;
        size?: ButtonSize;
        icon: FC<ComponentPropsWithoutRef<'svg'>>;
        className?: string;
        classes?: Classes<'root' | 'icon'>;
        isLoading?: boolean;
    }
>;

export const IconButton: FC<IconButtonProps> = forwardRef<HTMLButtonElement, IconButtonProps>(
    (props, ref) => {
        const {
            variant,
            icon: Icon,
            className,
            classes,
            size,
            disabled = false,
            isLoading = false,
            ...restProps
        } = props;

        const composedClassName = clsx(
            styles.root,
            className,
            classes?.root,
            variantStyles[variant],
            size !== undefined ? sizeToClassMap[size] : null,
            isLoading && styles.hasSpinner,
        );

        return (
            <button
                ref={ref}
                type="button"
                className={composedClassName}
                disabled={disabled || isLoading}
                {...restProps}
            >
                <Icon className={styles.icon} />
            </button>
        );
    },
);

IconButton.displayName = 'IconButton';

export type IconButtonLinkProps = Readonly<
    ComponentPropsWithoutRef<typeof Link> & {
        variant: ButtonVariant;
        size?: ButtonSize;
        icon: FC<ComponentPropsWithoutRef<'svg'>>;
        className?: string;
        classes?: Classes<'root' | 'icon'>;
    }
>;

export const IconButtonLink: FC<IconButtonLinkProps> = forwardRef<
    HTMLAnchorElement,
    IconButtonLinkProps
>((props, ref) => {
    const { variant, icon: Icon, className, classes, size, ...restProps } = props;

    const composedClassName = clsx(
        styles.root,
        className,
        classes?.root,
        variantStyles[variant],
        size !== undefined ? sizeToClassMap[size] : null,
    );

    return (
        <Link ref={ref} className={composedClassName} {...restProps}>
            <Icon className={styles.icon} />
        </Link>
    );
});

IconButtonLink.displayName = 'IconButtonLink';
