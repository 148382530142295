import type { IncomingHttpHeaders } from 'http';

/* pass some frontend headers to backend */
export const proxyHeaders = (
    headers: IncomingHttpHeaders,
    headersToSet = ['cache-control', 'cookie', 'connection', 'pragma', 'user-agent'],
): IncomingHttpHeaders => {
    const newHeaders: IncomingHttpHeaders = {};
    headersToSet.forEach((header) => {
        if (header in headers) newHeaders[header] = headers[header];
    });

    return newHeaders;
};
