import type { captureException as CaptureException } from '@sentry/nextjs';
import type { CaptureContext } from '@sentry/types';
import { z } from 'zod';

export type AuthorizationHeader = { Authorization: string } | Record<string, never>;

export enum AuthorizationType {
    Basic = 'Basic',
    Bearer = 'Bearer',
}

export type Token = string | null;
export type TokenGetter = () => Token;
export type TokenSetter = (token: Token) => void;

const errorMessageSchema = z.object({
    code: z.string(),
    message: z.string(),
});

const propertyErrorSchema = z.record(z.array(errorMessageSchema));

export const apiErrorResponseSchema = z.object({
    error: propertyErrorSchema,
});

export type ErrorMessage = z.infer<typeof errorMessageSchema>;
export type PropertyError = z.infer<typeof propertyErrorSchema>;
export type ApiErrorResponse = z.infer<typeof apiErrorResponseSchema>;

export enum HttpMethod {
    Get = 'GET',
    Post = 'POST',
}

export type CaptureExceptionSignature = typeof CaptureException;
export type RequestPayload = Record<string, unknown> | null | undefined;
export type RequestContext = {
    url: string;
    method: HttpMethod;
    headers: HeadersInit;
    payload: RequestPayload;
};

export type { CaptureContext };
