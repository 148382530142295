import type { Lens } from 'monocle-ts';

export function applyLensPayload<S, A>(lens: Lens<S, A>): (state: S, payload: A) => S {
    return (state, payload) => lens.set(payload)(state);
}

export const modifyPayloadLens =
    <T, S, V>(lens: Lens<S, V>, f: (value: V, payload: T) => V) =>
    (state: S, payload: T): S =>
        lens.modify((value) => f(value, payload))(state);

export const transformPayloadLens =
    <T, S, V>(lens: Lens<S, V>, f: (payload: T) => V) =>
    (state: S, payload: T): S =>
        lens.set(f(payload))(state);
