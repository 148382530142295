import Cookies from 'js-cookie';

import { attach, sample, type StoreValue } from 'effector';
import { persist } from 'effector-storage';

import { F, IO, IOOption, N, O, Predicate, RA, S } from '@feip-internal/fp-ts';

import { clearSessionEv } from '@stores/app';
import { comparisonApartments } from '@stores/comparison-apartments/domian';
import { fetchComparisonApartmentsFx } from '@stores/comparison-apartments/server-side';

import type { NumericId } from '@api/generated';
import { apiRefineComparisonApartmentIds } from '@api/methods';
import type { CompareApartmentsPayload, CompareApartmentsResult } from '@api/protocol';

import { apartmentsComparisonMaxIds, TooManyApartmentIdsError } from './lib';

export const $comparisonApartments = comparisonApartments
    .createStore<CompareApartmentsResult>({ items: [] })
    .on(fetchComparisonApartmentsFx.doneData, F.SK)
    .reset([clearSessionEv]);

export const pickupApartmentComparisonIds = comparisonApartments.createEvent();

export const $comparisonApartmentsIds = comparisonApartments.createStore<readonly NumericId[]>([]);

export const fetchComparisonApartmentsByIds = attach({
    effect: fetchComparisonApartmentsFx,
    source: $comparisonApartmentsIds,
    mapParams: (_: void, ids): CompareApartmentsPayload => ({
        apartmentIds: ids.map((item) => String(item)),
    }),
});

sample({
    clock: $comparisonApartmentsIds,
    target: fetchComparisonApartmentsByIds,
});

export const toggleApartmentComparisonFx = attach({
    source: $comparisonApartmentsIds,
    effect: async (ids, id: number): Promise<readonly number[]> => {
        const apartmentIds = F.pipe(
            ids,
            RA.elem(N.Eq)(id, ids) ? RA.filter((otherId) => otherId !== id) : RA.append(id),
        );
        try {
            const { items } = await apiRefineComparisonApartmentIds({
                apartmentIds: apartmentIds.map(String),
            });
            return items;
        } catch (error) {
            if (RA.size(apartmentIds) > apartmentsComparisonMaxIds) {
                throw new TooManyApartmentIdsError();
            }
            throw error;
        }
    },
});

export const removeComparisonApartmentsIds = attach({
    source: $comparisonApartmentsIds,
    effect: async (ids, deletedIds: number[]): Promise<readonly number[]> => {
        const apartmentIds = F.pipe(
            ids,
            RA.filter((otherId) => !deletedIds.includes(otherId)),
        );
        try {
            const { items } = await apiRefineComparisonApartmentIds({
                apartmentIds: apartmentIds.map(String),
            });
            return items;
        } catch (error) {
            throw error;
        }
    },
});

sample({
    clock: [toggleApartmentComparisonFx.doneData, removeComparisonApartmentsIds.doneData],
    target: $comparisonApartmentsIds,
});

export const apartmentComparisonCookieKey = 'apartmentComparisonIds';

export const commaSeparatedNumbersAsStringToArray = (
    commaSeparatedNumbersAsString: string,
): readonly number[] =>
    F.pipe(
        commaSeparatedNumbersAsString,
        S.split(','),
        RA.traverse(O.Applicative)(O.fromPredicate(Predicate.not(S.isEmpty))),
        O.map(RA.map(Number)),
        O.chain(RA.traverse(O.Applicative)(O.fromPredicate(Number.isSafeInteger))),
        O.filter((ids) => RA.size(ids) <= apartmentsComparisonMaxIds),
        O.getOrElseW(F.constant([])),
    );

persist({
    store: $comparisonApartmentsIds,
    pickup: pickupApartmentComparisonIds,
    adapter: <State>() => ({
        get: () =>
            F.pipe(
                () => O.fromNullable(Cookies.get(apartmentComparisonCookieKey)),
                IOOption.match(() => [], commaSeparatedNumbersAsStringToArray),
                IO.map(F.unsafeCoerce<StoreValue<typeof $comparisonApartmentsIds>, State>),
            )(),
        set: (value: State) =>
            F.pipe(
                value,
                F.unsafeCoerce<State, StoreValue<typeof $comparisonApartmentsIds>>,
                RA.map(String),
                RA.intercalate(S.Monoid)(','),
                IO.of,
                IO.map((serializedValue) =>
                    Cookies.set(apartmentComparisonCookieKey, serializedValue),
                ),
            )(),
    }),
});
