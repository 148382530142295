import type { PropsWithChildren } from 'react';
import { PureComponent } from 'react';

import type { SeoData } from '@api/generated';

import { trackException } from '@utils/sentry';

import { DefaultLayout } from '@layouts/DefaultLayout/DefaultLayout';

import { Container } from '@components/common/Container/Container';

type ErrorBoundaryState = { hasError: boolean };

const seoData: SeoData = { title: 'Ошибка', description: null, keywords: null };

export class ErrorBoundary extends PureComponent<PropsWithChildren, ErrorBoundaryState> {
    constructor(props: PropsWithChildren) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: Error) {
        trackException(error);
    }

    render() {
        const { hasError } = this.state;
        const { children } = this.props;

        if (hasError) {
            return (
                <DefaultLayout seoData={seoData}>
                    <Container>Произошла ошибка, попробуйте зайти позже</Container>
                </DefaultLayout>
            );
        }

        return children;
    }
}
