/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/**
 * @pattern ^(?<minimum>0|[1-9]\d*|null)-(?<maximum>0|[1-9]\d*|null)$
 * @example "1000-2000"
 */
export type RangeQueryParameter = Readonly<string>;

/**
 * A multiselect filter query parameter for rooms
 * @example ["one","two","studio"]
 */
export type RoomsMultiselectQueryParameter = Readonly<RoomsFilterEnum[]>;

/**
 * A multiselect filter query parameter for locality
 * @example ["Sadgorod","Zarya"]
 */
export type LocalityMultiselectQueryParameter = Readonly<LocalityEnum[]>;

/**
 * A multiselect filter query parameter for statuses
 * @example ["booked","sold"]
 */
export type StatusMultiselectQueryParameter = Readonly<StatusesFilterEnum[]>;

/**
 * A multiselect filter query parameter 1. Consisting of comma-separated values
 *    representing IDs, enums, or values as strings of enabled options.
 * 2. This parameter can't start or end with comma, this should cause an error. 3. Multi-digit integer values can't start with 0, this should cause an error. 4. Comma-separated values should no be repeated.
 * @example [1,2,3]
 */
export type MultiselectQueryParameter = Readonly<string[]>;

export type SeoData = Readonly<{
    readonly title: string | null;
    readonly description: string | null;
    readonly keywords: string | null;
}>;

/** Floor index 1. Is null for roof. 2. Is positive integer for floors. 3. Is -1 for ground floor. */
export type FloorIndex = Readonly<SafeInteger64 | null>;

export type ErrorBody = Readonly<{
    readonly code: string;
    readonly message: string;
}>;

export type Error = Readonly<{
    readonly errors?: Record<string, ErrorBody[]>;
}>;

/**
 * A safe integer values that can be represented by 64-bit IEEE 754 floating point value https://en.wikipedia.org/wiki/Double-precision_floating-point_format https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/MAX_SAFE_INTEGER https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/MIN_SAFE_INTEGER
 * @format int64
 * @min -9007199254740991
 * @max 9007199254740991
 */
export type SafeInteger64 = Readonly<number>;

/**
 * A non-±Infinity, non-NaN 64-bit IEEE 754 floating point value
 * @min 5e-324
 * @max 1.7976931348623157e+308
 */
export type FiniteFloat64 = Readonly<number>;

/**
 * Rubles
 * Russian Rubles
 */
export type Rubles = Readonly<SafeInteger64>;

export type PaginatedList = Readonly<{
    /**
     * Current page
     * @format int64
     * @example 1
     */
    readonly page: number;
    /**
     * Page size
     * @format int64
     * @example 10
     */
    readonly page_size: number;
    /**
     * Total list size (All pages)
     * @format int64
     * @example 50
     */
    readonly total: number;
}>;

export enum ImageFormat {
    Avif = 'avif',
    Jpeg = 'jpeg',
    Png = 'png',
    Svg = 'svg',
    Webp = 'webp',
}

export type ImageUrl = Readonly<{
    readonly format: ImageFormat;
    readonly url: string;
}>;

export type Image = Readonly<{
    readonly urls: ImageUrl[];
    /** @format int64 */
    readonly width: number;
    /** @format int64 */
    readonly height: number;
}>;

/**
 * @maxItems 2
 * @minItems 2
 */
export type ImagePair = Readonly<[Image, Image]>;

/** small, medium and large size is unique. blur size is 70x70 for any image. */
export type ImageSetWeb = Readonly<{
    readonly small: ImagePair;
    readonly medium: ImagePair;
    readonly large: ImagePair;
    readonly blur: ImageUrl;
}>;

export type ImageSet = Readonly<{
    /** small, medium and large size is unique. blur size is 70x70 for any image. */
    readonly web: ImageSetWeb;
}>;

export type OrientedImageSet = Readonly<{
    readonly landscape: ImageSet;
    readonly portrait: ImageSet;
}>;

/** URL of SVG file */
export type SVGURL = Readonly<string>;

export type SchemeSVGURL = Readonly<SVGURL>;

export type Slug = Readonly<string>;

/** @format int64 */
export type NumericId = Readonly<number>;

/**
 * Russian phone number in 71112223344 format (I.e. with 7 at the beginning, with + sign omitted)
 * @pattern 7\d{10}
 */
export type RussianPhoneNumber = Readonly<string>;

export type YearAndQuarter = Readonly<{
    /**
     * @format int64
     * @min 2000
     * @max 2100
     */
    readonly year: number;
    /**
     * @format int64
     * @min 1
     * @max 4
     */
    readonly quarter: number;
}>;

/** @format double */
export type Coordinate = Readonly<number>;

export type Coordinates = Readonly<{
    readonly latitude: Coordinate;
    readonly longitude: Coordinate;
}>;

export type Slide = Readonly<{
    readonly image: ImageSet;
}>;

export type VideoSlide = Readonly<{
    readonly loopVideo: boolean;
    /** @example "/fixture-video/landscape.av1-opus.mp4" */
    readonly landscapeMp4Av1OpusUrl: string;
    /** @example "/fixture-video/landscape.hevc-aac.mp4" */
    readonly landscapeMp4HevcAacUrl: string;
    /** @example "/fixture-video/landscape.h264-aac.mp4" */
    readonly landscapeMp4H264AacUrl: string;
    readonly landscapeFallbackImage: ImageSet;
    /** @example "/fixture-video/portrait.av1-opus.mp4" */
    readonly portraitMp4Av1OpusUrl: string;
    /** @example "/fixture-video/portrait.hevc-aac.mp4" */
    readonly portraitMp4HevcAacUrl: string;
    /** @example "/fixture-video/portrait.h264-aac.mp4" */
    readonly portraitMp4H264AacUrl: string;
    readonly portraitFallbackImage: ImageSet;
}>;

export type EitherSlideVideo = Readonly<
    {
        readonly kind: 'video';
    } & VideoSlide
>;

export type EitherSlideImage = Readonly<
    {
        readonly kind: 'image';
        readonly image: OrientedImageSet;
    }
>;

export type EitherSlide = Readonly<EitherSlideImage | EitherSlideVideo>;

export type ExpandableSlide = Readonly<
    Slide & {
        readonly fullSizeImage: ImageSet;
    }
>;

export enum StatusesEnum {
    Available = 'available',
    Booked = 'booked',
    Sold = 'sold',
}

export enum RoomsNumberEnum {
    Studio = 'studio',
    One = 'one',
    Two = 'two',
    Three = 'three',
    Four = 'four',
    Five = 'five',
    Six = 'six',
    Seven = 'seven',
}

export type Document = Readonly<{
    readonly name: string;
    readonly fileUrl: string;
}>;

export enum LightSide {
    East = 'east',
    West = 'west',
    North = 'north',
    South = 'south',
    NorthEast = 'north_east',
    NorthWest = 'north_west',
    SouthEast = 'south_east',
    SouthWest = 'south_west',
}

export enum WeekdaysEnum {
    Monday = 'monday',
    Tuesday = 'tuesday',
    Wednesday = 'wednesday',
    Thursday = 'thursday',
    Friday = 'friday',
    Saturday = 'saturday',
    Sunday = 'sunday',
}

export enum ProjectPlanEnum {
    Building = 'building',
    Floors = 'floors',
}

export type OfficeSettings = Readonly<{
    readonly address: string | null;
    readonly workingHours: string | null;
}>;

export type SeoSettings = Readonly<{
    readonly title: string | null;
    readonly description: string | null;
    readonly siteTitle: string | null;
    /** 1200x630 */
    readonly image: ImageSet | null;
}>;

export type ContactsSettings = Readonly<{
    readonly salesDepartmentPhone: RussianPhoneNumber;
    readonly developerOfficePhone: RussianPhoneNumber;
    readonly whatsappUrl: WhatsappURL | null;
    readonly telegramUrl: TelegramURL | null;
}>;

export type Settings = Readonly<{
    readonly seo: SeoSettings;
    readonly contacts: ContactsSettings;
    readonly office: OfficeSettings;
    readonly googleTagManagerId: string;
    readonly yandexSmartCaptchaSiteKey: string;
    readonly yandexMapsKey: string;
}>;

export type MenuItem = Readonly<{
    readonly id: NumericId;
    readonly url: string;
    readonly title: string;
    readonly shouldOpenInNewTab: boolean;
}>;

export type Header = Readonly<{
    readonly menu: MenuItem[];
}>;

export type Footer = Readonly<{
    readonly menu: MenuItem[];
    readonly projects: ProjectBase[];
}>;

export type CommonData = Readonly<{
    readonly header: Header;
    readonly footer: Footer;
    readonly settings: Settings;
}>;

export type WhatsappURL = Readonly<string>;

export type TelegramURL = Readonly<string>;

export type SelfHostedVideo = Readonly<{
    /** @example "/fixture-video/landscape.av1-opus.mp4" */
    readonly mp4Av1OpusUrl: string;
    /** @example "/fixture-video/landscape.hevc-aac.mp4" */
    readonly mp4HevcAacUrl: string;
    /** @example "/fixture-video/landscape.h264-aac.mp4" */
    readonly mp4H264AacUrl: string;
    /** small 592x312, medium 880x464, large 1140x600 */
    readonly poster: ImageSet;
}>;

export enum RoomsFilterEnum {
    Studio = 'studio',
    One = 'one',
    Two = 'two',
    ThreeOrMore = 'three_or_more',
}

export enum StatusesFilterEnum {
    Booked = 'booked',
    Sold = 'sold',
}

export enum LocalityEnum {
    Tikhaia = 'tikhaia',
    Zarya = 'zarya',
    VtoraiaRechka = 'vtoraia_rechka',
    PervaiaRechka = 'pervaia_rechka',
    Centr = 'centr',
    Churkin = 'churkin',
    Zmeinka = 'zmeinka',
    Lugovaja = 'lugovaja',
    Dalhimprom = 'dalhimprom',
    Yegersheld = 'yegersheld',
    Nejbuta = 'nejbuta',
    TretiaRabochaja = 'tretia_rabochaja',
    Baljaeva = 'baljaeva',
    Russkij = 'russkij',
    SnegovajaPad = 'snegovaja_pad',
    Patrokl = 'patrokl',
    Bam = 'bam',
    Sadgorod = 'sadgorod',
    Sedanka = 'sedanka',
    DalniyPrigorod = 'dalniy_prigorod',
    Nadezhdinskij = 'nadezhdinskij',
    Artjom = 'artjom',
}

/** A multiselect filter option in backend response body. */
export type SearchMultiselectFilterOptionBase = Readonly<{
    readonly name: string;
    readonly isSelected: boolean;
}>;

export type SearchStatusMultiselectFilterOption = Readonly<
    SearchMultiselectFilterOptionBase & {
        readonly value: StatusesFilterEnum;
    }
>;

export type SearchRoomsMultiselectFilterOption = Readonly<
    SearchMultiselectFilterOptionBase & {
        readonly value: RoomsFilterEnum;
    }
>;

export type SearchLocalityMultiselectFilterOption = Readonly<
    SearchMultiselectFilterOptionBase & {
        readonly value: LocalityEnum;
    }
>;

export type SearchMultiselectFilterOption = Readonly<
    SearchMultiselectFilterOptionBase & {
        readonly value: string;
    }
>;

/** A multiselect filter in backend response body. 1. Available options are expected to be independent of request payload. */
export type SearchStatusMultiselectFilter = Readonly<SearchStatusMultiselectFilterOption[]>;

/** A multiselect filter in backend response body. 1. Available options are expected to be independent of request payload. */
export type SearchRoomsMultiselectFilter = Readonly<SearchRoomsMultiselectFilterOption[]>;

/** A multiselect filter in backend response body. 1. Available options are expected to be independent of request payload. */
export type SearchLocalityMultiselectFilter = Readonly<SearchLocalityMultiselectFilterOption[]>;

/** A multiselect filter in backend response body. 1. Available options are expected to be independent of request payload. */
export type SearchMultiselectFilter = Readonly<SearchMultiselectFilterOption[]>;

/**
 * An integer range filter in backend response body. 1. If min value is absent in request payload,
 *    `selectedMin` should be equal to `availableMin`.
 * 2. If max value is absent in request payload,
 *    `selectedMax` should be equal to `availableMax`.
 * 3. Available values are expected to be independent of request payload.
 */
export type SearchIntegerRangeFilter = Readonly<{
    readonly kind: 'integer';
    /** Russian Rubles */
    readonly availableMin: Rubles;
    /** Russian Rubles */
    readonly availableMax: Rubles;
    readonly selectedMin: Rubles | null;
    readonly selectedMax: Rubles | null;
}>;

/**
 * A float range filter in backend response body. 1. If min value is absent in request payload,
 *    `selectedMin` should be equal to `availableMin`.
 * 2. If max value is absent in request payload,
 *    `selectedMax` should be equal to `availableMax`.
 * 3. Available values are expected to be independent of request payload.
 */
export type SearchFloatRangeFilter = Readonly<{
    readonly kind: 'float';
    /** A non-±Infinity, non-NaN 64-bit IEEE 754 floating point value */
    readonly availableMin: FiniteFloat64;
    /** A non-±Infinity, non-NaN 64-bit IEEE 754 floating point value */
    readonly availableMax: FiniteFloat64;
    readonly selectedMin: FiniteFloat64 | null;
    readonly selectedMax: FiniteFloat64 | null;
}>;

export type SearchRangeFilter = Readonly<SearchIntegerRangeFilter | SearchFloatRangeFilter>;

export type SearchFilters = Readonly<{
    /** A multiselect filter in backend response body. 1. Available options are expected to be independent of request payload. */
    readonly roomsNumber: SearchRoomsMultiselectFilter;
    /** A multiselect filter in backend response body. 1. Available options are expected to be independent of request payload. */
    readonly locality: SearchLocalityMultiselectFilter;
    /** A multiselect filter in backend response body. 1. Available options are expected to be independent of request payload. */
    readonly project: SearchMultiselectFilter;
    /** A multiselect filter in backend response body. 1. Available options are expected to be independent of request payload. */
    readonly completionYear: SearchMultiselectFilter;
    /** A multiselect filter in backend response body. 1. Available options are expected to be independent of request payload. */
    readonly advantages: SearchMultiselectFilter;
    readonly price: SearchRangeFilter;
    /** Total area of the apartment */
    readonly area: SearchRangeFilter;
}>;

export type ProjectPreviewNearestBusStop = Readonly<{
    readonly title: string;
    /** @format int64 */
    readonly travelTimeMinutes: number | null;
}>;

export type ProjectBase = Readonly<{
    readonly id: NumericId;
    readonly slug: Slug;
    readonly title: string;
    readonly planKind: ProjectPlanEnum;
}>;

export type ProjectPreview = Readonly<
    ProjectBase & {
        readonly description: string;
        /** small 607x300, medium 850x420, large 562x277 */
        readonly image: ImageSet;
        readonly status: string;
        readonly address: string;
        readonly nearestBusStop: ProjectPreviewNearestBusStop | null;
        /** Null when apartments are sold out */
        readonly minPriceRub: Rubles | null;
        readonly coordinates: Coordinates;
        readonly availableApartmentsCount: number;
    }
>;

export type HomePurchaseMethod = Readonly<{
    readonly title: string;
    readonly subtitle: string;
    readonly description: string;
}>;

export type HomeManagingCompany = Readonly<{
    readonly title: string;
    /** landscape: small 600x320, medium 850x450, large 1140x600 portrait: small 580x600, medium 580x600, large 580x600 */
    readonly image: OrientedImageSet;
    readonly url: string;
    readonly description: string | null;
}>;

export type HomeAboutCompany = Readonly<
    AboutLogoSection & {
        readonly title: string;
        readonly description: string;
    }
>;

export type HomeStoryStepAction = Readonly<{
    readonly title: string;
    readonly url: string;
}>;

export type HomeStoryStep = Readonly<{
    readonly id: NumericId;
    /** small 334x576, medium 368x636, large 420x724 */
    readonly image: ImageSet;
    /**
     * Story step duration in seconds
     * @format int64
     */
    readonly duration: number | null;
    readonly action: HomeStoryStepAction | null;
}>;

export type HomeStory = Readonly<{
    readonly id: NumericId;
    /** 180x310 */
    readonly poster: ImageSet;
    readonly steps: HomeStoryStep[];
}>;

export type HomeHeroSlideButton = Readonly<{
    readonly url: string;
    readonly text: string;
}>;

export type HomeHeroSlide = Readonly<
    EitherSlide & {
        readonly button: HomeHeroSlideButton | null;
    }
>;

export type HomeResult = Readonly<{
    /** small 640x560, medium 900x620, large 1440x708 */
    readonly slides: HomeHeroSlide[];
    readonly stories: HomeStory[];
    readonly searchFilters: SearchFilters;
    readonly projects: ProjectPreview[];
    readonly purchaseMethods: HomePurchaseMethod[];
    readonly managingCompany: HomeManagingCompany;
    readonly aboutCompany: HomeAboutCompany;
    readonly seo: SeoData;
}>;

export type AboutLogoSection = Readonly<{
    readonly title: string;
    /** small 640x788, medium 900x1108, large 1440x1773 */
    readonly image: ImageSet;
    /** small 640x533, medium 900x750, large 1140x1200 */
    readonly imagePortrait: ImageSet;
}>;

export type AboutAdvantages = Readonly<{
    readonly title: string;
    readonly description: string;
    readonly image: ImageSet;
}>;

export type AboutTeam = Readonly<{
    readonly title: string;
    readonly description: string;
    /** small 600x380, medium 850x537, large 1140x720 */
    readonly gallery: ImageSet[];
}>;

export type AboutHistory = Readonly<{
    readonly title: string;
    readonly video: SelfHostedVideo;
}>;

export type AboutResult = Readonly<{
    readonly logoSection: AboutLogoSection;
    readonly advantages: AboutAdvantages;
    readonly team: AboutTeam;
    readonly history: AboutHistory;
    readonly seo: SeoData;
}>;

/** Requisites for each legal entity/developer */
export type Requisite = Readonly<{
    readonly id: NumericId;
    readonly title: string;
    readonly name: string;
    readonly address: string;
    /** Russian phone number in 71112223344 format (I.e. with 7 at the beginning, with + sign omitted) */
    readonly phone: RussianPhoneNumber;
    readonly email: string;
    /**
     * ИНН
     * Идентификационный Номер Налогоплательщика
     */
    readonly inn: string;
    /**
     * ОГРН
     * Основной Государственный Регистрационный Номер
     */
    readonly ogrn?: string;
}>;

export type RequisitesResult = Readonly<{
    readonly items: Requisite[];
}>;

export type StaticPage = Readonly<{
    readonly id: NumericId;
    readonly slug: Slug;
    readonly title: string;
    /** small 600x263, medium 850x372, large 1140x500 */
    readonly image: ImageSet | null;
    readonly text: string;
    readonly seo: SeoData;
}>;

export type StaticPageSlugs = Readonly<{
    readonly items: string[];
}>;

export type ProjectBlockBase = Readonly<{
    /** A safe integer values that can be represented by 64-bit IEEE 754 floating point value https://en.wikipedia.org/wiki/Double-precision_floating-point_format https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/MAX_SAFE_INTEGER https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/MIN_SAFE_INTEGER */
    readonly blockId: SafeInteger64;
}>;

export type ProjectOtherProjectsBlock = Readonly<
    ProjectBlockBase & {
        readonly kind: 'other_projects';
        readonly items: ProjectPreview[];
    }
>;

export type ProjectLandingSlideBlock = Readonly<
    ProjectBlockBase & {
        readonly kind?: 'landing_slide';
        /** small 640x560, medium 900x620, large 1440x708 */
        readonly slides: ImageSet[];
        /** @format int64 */
        readonly remainingApartments: number;
        /** @format int64 */
        readonly remainingStorerooms: number;
        /** @format int64 */
        readonly remainingParkingSpots: number;
        readonly text: string | null;
    }
>;

export type ProjectMainInfo = Readonly<
    (ProjectBase & ProjectBlockBase) & {
        readonly kind: 'main_info';
        readonly description: string;
        /** image: small 607x813, medium 850x480, large 755x500 fullSizeImage: small 640x600, medium 900x620, large 1440x788 */
        readonly slides: ExpandableSlide[];
        readonly parameters: string[];
    }
>;

export type ProjectLocationBlock = Readonly<
    ProjectBlockBase & {
        readonly kind: 'location';
        readonly title: string;
        readonly description: string | null;
        readonly address: string;
        readonly twoGisUrl: string;
        readonly coordinates: Coordinates;
        readonly transportReach: string[];
        /** URL for <iframe> element 'src' attribute */
        readonly mapHtml: string;
    }
>;

export type ProjectParameterPreview = Readonly<{
    readonly id: NumericId;
    readonly title: string;
    readonly description: string;
    /** small 450x640, medium 415x593, large 270x385 */
    readonly image: ImageSet | null;
}>;

export type ProjectParametersBlock = Readonly<
    ProjectBlockBase & {
        readonly kind: 'parameters';
        readonly title: string;
        readonly description: string;
        readonly parameters: ProjectParameterPreview[];
    }
>;

export type ProjectStoreroomsBlock = Readonly<
    ProjectBlockBase & {
        readonly kind: 'storerooms';
        readonly title: string;
        readonly description: string | null;
        /** image: small 607x813, medium 850x480, large 755x500 fullSizeImage: small 640x600, medium 900x620, large 1440x788 */
        readonly slides: ExpandableSlide[];
    }
>;

export type ProjectParkingSpotsBlock = Readonly<
    ProjectBlockBase & {
        readonly kind: 'parking_spots';
        readonly title: string;
        readonly description: string | null;
        /** image: small 607x813, medium 850x480, large 755x500 fullSizeImage: small 640x600, medium 900x620, large 1440x788 */
        readonly slides: ExpandableSlide[];
    }
>;

export type ProjectShowroomBlock = Readonly<
    ProjectBlockBase & {
        readonly kind: 'showroom';
        readonly title: string;
        readonly description: string;
        /** small 600x320, medium 850x450, large 1140x600 */
        readonly backgroundImage: ImageSet;
    }
>;

export type ProjectBuildPhase = Readonly<{
    readonly id: NumericId;
    readonly title: string;
    readonly completionDate: YearAndQuarter;
    readonly isCompleted: boolean;
}>;

export type ProjectBuildPhasesBlock = Readonly<
    ProjectBlockBase & {
        readonly kind: 'build_phases';
        readonly title: string;
        /**
         * @format int64
         * @min 0
         * @max 100
         */
        readonly totalProgress: number;
        readonly buildPhases: ProjectBuildPhase[];
    }
>;

export type ProjectSalesDepartmentEmployeePreview = Readonly<{
    readonly fullName: string;
    /** small 125x125, medium 200x200, large 200x200 */
    readonly photo: ImageSet | null;
    readonly whatsappUrl: WhatsappURL;
    readonly jobTitle: string | null;
}>;

export type ProjectSalesDepartmentBlock = Readonly<
    ProjectBlockBase & {
        readonly kind: 'sales_department';
        readonly title: string;
        readonly description: string | null;
        /** Russian phone number in 71112223344 format (I.e. with 7 at the beginning, with + sign omitted) */
        readonly phoneNumber: RussianPhoneNumber;
        readonly employees: ProjectSalesDepartmentEmployeePreview[];
    }
>;

export type ProjectBlockLinkButton = Readonly<{
    readonly name: string;
    readonly url: string;
}>;

export type ProjectManagingCompanyBlock = Readonly<
    ProjectBlockBase & {
        readonly kind: 'managing_company';
        readonly title: string;
        readonly description: string | null;
        /** large 1440x600, medium 850x450, small 608x320 */
        readonly backgroundImage: ImageSet;
        readonly button: ProjectBlockLinkButton | null;
    }
>;

export type ProjectVideoBlock = Readonly<
    ProjectBlockBase & {
        readonly kind: 'video';
        readonly title: string;
        readonly video: SelfHostedVideo;
    }
>;

export type ProjectHeader = Readonly<
    ProjectBase & {
        readonly logo: SVGURL;
        readonly logoAtDark: SVGURL;
    }
>;

export type ProjectAnyBlock = Readonly<
    | ProjectMainInfo
    | ProjectLandingSlideBlock
    | ProjectLocationBlock
    | ProjectParametersBlock
    | ProjectShowroomBlock
    | ProjectStoreroomsBlock
    | ProjectParkingSpotsBlock
    | ProjectBuildPhasesBlock
    | ProjectSalesDepartmentBlock
    | ProjectOtherProjectsBlock
    | ProjectVideoBlock
    | ProjectManagingCompanyBlock
>;

export type ProjectDetails = Readonly<
    ProjectPreview & {
        readonly header: ProjectHeader;
        /** @minItems 1 */
        readonly blocks: ProjectAnyBlock[];
        readonly seo: SeoData;
    }
>;

export type ProjectList = Readonly<{
    readonly title: string;
    readonly items: ProjectPreview[];
    readonly seo: SeoData;
}>;

export type DocumentsGroup = Readonly<{
    readonly title: string;
    /** @minItems 1 */
    readonly files: Document[];
}>;

export type DocumentsGroupsList = Readonly<{
    readonly items: DocumentsGroup[];
}>;

export type SearchResult = Readonly<
    PaginatedList & {
        readonly items: ApartmentPreview[];
        readonly filters: SearchFilters;
        readonly seo: SeoData;
    }
>;

export type ApartmentPlaningSlide = Readonly<
    ExpandableSlide & {
        readonly name: string;
    }
>;

export type ApartmentPrices = Readonly<{
    /** Russian Rubles */
    readonly pricePerSquareMeter: Rubles;
    /** Russian Rubles */
    readonly priceTotal: Rubles;
}>;

/** image: small 590x640, medium 900x700, large 720x560 */
export type ApartmentSlide = Readonly<
    Slide & {
        readonly name: string;
    }
>;

export type ApartmentImagesTabs = Readonly<{
    /** This array may be empty */
    readonly planing: ApartmentSlide[];
    /** This array may be empty */
    readonly floor: ApartmentSlide[];
    /** This array may be empty */
    readonly house: ApartmentSlide[];
    /** This array may be empty */
    readonly visualization: ApartmentSlide[];
}>;

export type ApartmentProject = Readonly<
    ProjectBase & {
        readonly locality: LocalityEnum;
    }
>;

export type Storeroom = Readonly<{
    readonly id: NumericId;
    /** @format int64 */
    readonly number: number;
    readonly status: StatusesEnum;
    /** A non-±Infinity, non-NaN 64-bit IEEE 754 floating point value */
    readonly squareMeters: FiniteFloat64;
    /** @format int64 */
    readonly house: number;
    /** @format int64 */
    readonly floor: number;
    readonly price: Rubles | null;
}>;

export type ParkingSpot = Readonly<{
    readonly id: NumericId;
    /** @format int64 */
    readonly number: string;
    readonly status: StatusesEnum;
    /** A non-±Infinity, non-NaN 64-bit IEEE 754 floating point value */
    readonly squareMeters: FiniteFloat64;
    /** @format int64 */
    readonly house: string;
    /** @format int64 */
    readonly floor: number;
    readonly price: Rubles | null;
}>;

export type ApartmentWithPolygon = Readonly<
    ApartmentPreview & {
        readonly polygon: SchemeSVGURL;
    }
>;

export type ProjectApartmentsFloorPlanMetadata = Readonly<{
    /** Floor index 1. Is null for roof. 2. Is positive integer for floors. 3. Is -1 for ground floor. */
    readonly floorIndex: FloorIndex;
    /** The number of apartments satisfying the filters. The default value is 'null', which signifies that filters have yet to be applied. */
    readonly foundApartmentsQuantity: SafeInteger64;
    readonly isSelected: boolean;
}>;

export type ProjectApartmentsFloorPlan = Readonly<{
    readonly header: ProjectHeader;
    /** 1440x810 */
    readonly background: ImageSet;
    /** Total number of apartments available for purchase */
    readonly availableApartments: SafeInteger64;
    /** Total number of apartments */
    readonly totalApartments: SafeInteger64;
    /** @minItems 1 */
    readonly floors: ProjectApartmentsFloorPlanMetadata[];
    readonly floorApartments: ApartmentWithPolygon[];
    readonly filters: ProjectApartmentsFloorPlanFilters;
    readonly seo: SeoData;
}>;

export type ProjectApartmentsFloorPlanFilters = Readonly<{
    /** A multiselect filter in backend response body. 1. Available options are expected to be independent of request payload. */
    readonly roomsNumber: SearchRoomsMultiselectFilter;
    /** A multiselect filter in backend response body. 1. Available options are expected to be independent of request payload. */
    readonly advantages: SearchMultiselectFilter;
    /**
     * An integer range filter in backend response body. 1. If min value is absent in request payload,
     *    `selectedMin` should be equal to `availableMin`.
     * 2. If max value is absent in request payload,
     *    `selectedMax` should be equal to `availableMax`.
     * 3. Available values are expected to be independent of request payload.
     */
    readonly price: SearchIntegerRangeFilter;
    /** Total area of the apartment */
    readonly area: SearchFloatRangeFilter;
    /** A multiselect filter in backend response body. 1. Available options are expected to be independent of request payload. */
    readonly statuses: SearchStatusMultiselectFilter;
}>;

export enum BuildingPlanStatusesFilterEnum {
    Booked = 'booked',
}

/**
 * A multiselect filter query parameter for statuses
 * @example ["booked","sold"]
 */
export type BuildingPlanStatusMultiselectQueryParameter = Readonly<
    BuildingPlanStatusesFilterEnum[]
>;

export type BuildingPlanStatusMultiselectFilterOption = Readonly<
    SearchMultiselectFilterOptionBase & {
        readonly value: BuildingPlanStatusesFilterEnum;
    }
>;

/** A multiselect filter in backend response body. 1. Available options are expected to be independent of request payload. */
export type BuildingPlanStatusMultiselectFilter = Readonly<
    BuildingPlanStatusMultiselectFilterOption[]
>;

export type BuildingPlanFilters = Readonly<{
    /** A multiselect filter in backend response body. 1. Available options are expected to be independent of request payload. */
    readonly roomsNumber: SearchRoomsMultiselectFilter;
    /** A multiselect filter in backend response body. 1. Available options are expected to be independent of request payload. */
    readonly advantages: SearchMultiselectFilter;
    /**
     * An integer range filter in backend response body. 1. If min value is absent in request payload,
     *    `selectedMin` should be equal to `availableMin`.
     * 2. If max value is absent in request payload,
     *    `selectedMax` should be equal to `availableMax`.
     * 3. Available values are expected to be independent of request payload.
     */
    readonly price: SearchIntegerRangeFilter;
    /** Total area of the apartment */
    readonly area: SearchFloatRangeFilter;
    /** A multiselect filter in backend response body. 1. Available options are expected to be independent of request payload. */
    readonly statuses: BuildingPlanStatusMultiselectFilter;
}>;

export type BuildingFloorApartmentKindMetadata = Readonly<{
    /** A safe integer values that can be represented by 64-bit IEEE 754 floating point value https://en.wikipedia.org/wiki/Double-precision_floating-point_format https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/MAX_SAFE_INTEGER https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/MIN_SAFE_INTEGER */
    readonly roomsNumber: SafeInteger64;
    /** Russian Rubles */
    readonly minPrice: Rubles;
    /** A non-±Infinity, non-NaN 64-bit IEEE 754 floating point value */
    readonly minArea: FiniteFloat64;
}>;

export type BuildingFloorPlanMetadataBase = Readonly<{
    /** Floor index 1. Is null for roof. 2. Is positive integer for floors. 3. Is -1 for ground floor. */
    readonly floorIndex: FloorIndex;
    readonly polygon: SchemeSVGURL;
}>;

export type NonEmptyBuildingFloorPlanMetadata = Readonly<
    BuildingFloorPlanMetadataBase & {
        readonly floorStatus: 'has_suitable_apartments' | 'has_no_suitable_apartments';
        /** @minItems 1 */
        readonly apartmentKinds: BuildingFloorApartmentKindMetadata[];
    }
>;

export type EmptyBuildingFloorPlanMetadata = Readonly<
    BuildingFloorPlanMetadataBase & {
        readonly floorStatus: 'sold_out';
    }
>;

export type BuildingFloorMetadata = Readonly<
    NonEmptyBuildingFloorPlanMetadata | EmptyBuildingFloorPlanMetadata
>;

export type BuildingPlan = Readonly<{
    readonly header: ProjectHeader;
    /** 1440x810 */
    readonly background: ImageSet;
    /** Total number of apartments available for purchase */
    readonly availableApartments: SafeInteger64;
    /** Total number of apartments */
    readonly totalApartments: SafeInteger64;
    /** @minItems 1 */
    readonly floors: BuildingFloorMetadata[];
    readonly filters: BuildingPlanFilters;
    readonly seo: SeoData;
}>;

export type ProjectStoreroomWithPolygon = Readonly<
    Storeroom & {
        readonly polygon: SchemeSVGURL;
    }
>;

export type ProjectStoreroomsFloorPlanHouseMetadata = Readonly<{
    readonly houseId: NumericId;
    /**
     * Title for the house button
     * @minLength 1
     */
    readonly houseTitle: string;
    /** The number of apartments satisfying the filters. The default value is 'null', which signifies that filters have yet to be applied. */
    readonly foundStoreroomsQuantity: SafeInteger64;
    readonly isSelected: boolean;
}>;

export type ProjectStoreroomsFloorPlanInfoBlock = Readonly<{
    readonly title?: string | null;
    readonly description?: string | null;
    readonly url?: string | null;
}>;

export type ProjectStoreroomsFloorPlan = Readonly<{
    readonly header: ProjectHeader;
    /** 1440x810 */
    readonly background: ImageSet;
    readonly title: string | null;
    readonly descriptions?: string | null;
    /** @minItems 1 */
    readonly houses: ProjectStoreroomsFloorPlanHouseMetadata[];
    readonly houseStorerooms: ProjectStoreroomWithPolygon[];
    readonly seo: SeoData;
    readonly infoBlock: ProjectStoreroomsFloorPlanInfoBlock;
}>;

export type ProjectParkingSpotsWithPolygon = Readonly<
    ParkingSpot & {
        readonly polygon: SchemeSVGURL;
    }
>;

export type ProjectParkingSpotsFloorPlanFloorMetadata = Readonly<{
    readonly floorIndex: NumericId;
    /** The number of apartments satisfying the filters. The default value is 'null', which signifies that filters have yet to be applied. */
    readonly foundParkingSpotsQuantity: SafeInteger64;
    readonly isSelected: boolean;
}>;

export type ProjectParkingSpotsFloorPlanInfoBlock = Readonly<{
    readonly title: string;
    readonly phone: string;
    readonly url: string;
}>;

export type ProjectParkingSpotsFloorPlan = Readonly<{
    readonly header: ProjectHeader;
    /** 1440x810 */
    readonly background: ImageSet;
    readonly title?: string | null;
    readonly descriptions?: string | null;
    /** @minItems 1 */
    readonly floors: ProjectParkingSpotsFloorPlanFloorMetadata[];
    readonly floorParkingSpots: ProjectParkingSpotsWithPolygon[];
    readonly seo: SeoData;
    readonly infoBlock: ProjectParkingSpotsFloorPlanInfoBlock;
}>;

export type ApartmentBase = Readonly<{
    readonly id: NumericId;
    readonly slug: Slug;
    readonly number: string;
    readonly houseNumber?: SafeInteger64;
    readonly floor: SafeInteger64;
    readonly squareMeters: number;
    readonly completionDate: YearAndQuarter;
    readonly rooms: RoomsNumberEnum;
    readonly project: ApartmentProject;
    readonly status: StatusesEnum;
    /** Russian Rubles */
    readonly pricePerSquareMeter: Rubles;
    /** Russian Rubles */
    readonly priceTotal: Rubles;
    readonly lightSide?: LightSide;
}>;

export type ApartmentPreview = Readonly<
    ApartmentBase & {
        /** image: small 575x575, medium 385x285, large 240x240 */
        readonly gallery: Slide[];
    }
>;

export type Apartment = Readonly<
    ApartmentBase & {
        readonly advantages: string[];
        readonly ceilingHeightMeters: number;
        readonly pdfFile: string | null;
        readonly imagesTabs: ApartmentImagesTabs;
        readonly description: string | null;
    }
>;

export type ApartmentDetails = Readonly<{
    readonly apartment: Apartment;
    /** Reusable block from the project page to which the apartment belongs. The block may not be displayed. */
    readonly locationBlock: ProjectLocationBlock | null;
    /** Reusable block from the project page to which the apartment belongs. The block may not be displayed. */
    readonly storeroomsBlock: ProjectStoreroomsBlock | null;
    readonly parkingSpotsBlock: ProjectParkingSpotsBlock | null;
    readonly similarApartments: ApartmentPreview[];
    readonly seo: SeoData;
}>;

export type Captcha = Readonly<{
    /**
     * Yandex SmartCaptcha token to verify the request is from a human
     * @minLength 1
     * @example "03AGdBq26cuT..."
     */
    readonly captchaToken: string;
}>;

/**
 * The date in YYYY-MM-DD format
 * @format date
 * @pattern ^\d{4}-\d{2}-\d{2}$
 * @example "2024-04-15"
 */
export type Date = Readonly<FeipApi.Date>;

export type ContactForm = Readonly<{
    /** Russian phone number in 71112223344 format (I.e. with 7 at the beginning, with + sign omitted) */
    readonly phone: RussianPhoneNumber;
    /**
     * The name of the person submitting
     * @minLength 1
     * @maxLength 100
     */
    readonly name: string;
}>;

export type ConsultationFormPayload = Readonly<Captcha & ContactForm>;

export type ExcursionFormPayload = Readonly<
    Captcha &
        ContactForm & {
            /** The date in YYYY-MM-DD format */
            readonly date: Date;
        }
>;

export type BookingFormPayload = Readonly<
    Captcha &
        ContactForm & {
            /**
             * @minLength 3
             * @maxLength 500
             */
            readonly message: string | null;
        }
>;

export type MortgageBank = Readonly<{
    readonly title: string;
    readonly logo: SVGURL;
}>;

export type MortgageOffer = Readonly<{
    readonly id: NumericId;
    readonly bank: MortgageBank;
    /** Описание предложения в паре слов, например: “Дальневосточная ипотека” */
    readonly offerType: string;
    /** Платёж в месяц */
    readonly monthlyPayment: Rubles;
    /** Ставка, % */
    readonly ratePercent: FiniteFloat64;
    /** Первоначальный взнос, % */
    readonly initialPaymentPercent: FiniteFloat64;
    /** Срок, лет */
    readonly termYears: number;
}>;

export type ApartmentMortgageFilters = Readonly<{
    readonly initialPaymentPercent: {
        readonly min: number;
        readonly max: number;
        readonly value: number;
    };
    readonly termInYears: {
        readonly min: number;
        readonly max: number;
        readonly value: number;
    };
}>;

export type ApartmentMortgage = Readonly<{
    readonly filters: ApartmentMortgageFilters;
    /** Сумма ипотеки */
    readonly mortgagePrice: Rubles;
    readonly items: MortgageOffer[];
}>;

export type ProjectsApartmentsPlanDetailParams = Readonly<{
    /** Project floor index parameter. */
    readonly floorIndex?: FloorIndex;
    /** Minimum apartment price (in russian kopecks) parameter. */
    readonly priceRangeMin?: Rubles;
    /** Maximum apartment price (in russian kopecks) parameter. */
    readonly priceRangeMax?: Rubles;
    /** Minimum total apartment area (in m²) parameter. */
    readonly areaRangeMin?: FiniteFloat64;
    /** Maximum total apartment area (in m²) parameter. */
    readonly areaRangeMax?: FiniteFloat64;
    /** Number of rooms multiselect parameter. */
    readonly roomsNumber?: RoomsMultiselectQueryParameter;
    /**
     * Advantages multiselect parameter.
     * @example [4,2,1]
     */
    readonly advantages?: MultiselectQueryParameter;
    /**
     * Statuses multiselect parameter.
     * @example ["booked","sold"]
     */
    readonly statuses?: StatusMultiselectQueryParameter;
    /** Project slug */
    readonly projectSlug: string;
}>;

export type ProjectsBuildingPlanDetailParams = Readonly<{
    /** Minimum apartment price (in russian kopecks) parameter. */
    readonly priceRangeMin?: Rubles;
    /** Maximum apartment price (in russian kopecks) parameter. */
    readonly priceRangeMax?: Rubles;
    /** Minimum total apartment area (in m²) parameter. */
    readonly areaRangeMin?: FiniteFloat64;
    /** Maximum total apartment area (in m²) parameter. */
    readonly areaRangeMax?: FiniteFloat64;
    /** Number of rooms multiselect parameter. */
    readonly roomsNumber?: RoomsMultiselectQueryParameter;
    /**
     * Advantages multiselect parameter.
     * @example [4,2,1]
     */
    readonly advantages?: MultiselectQueryParameter;
    /**
     * Statuses multiselect parameter.
     * @example ["booked"]
     */
    readonly statuses?: BuildingPlanStatusMultiselectQueryParameter;
    /** Project slug */
    readonly projectSlug: string;
}>;

export type ProjectsStoreroomsPlanDetailParams = Readonly<{
    /**
     * Project house id parameter.
     * @example 5
     */
    readonly houseId?: number;
    /** Project slug */
    readonly projectSlug: string;
}>;

export type ProjectsParkingSpotsPlanDetailParams = Readonly<{
    /**
     * Project floor index parameter.
     * @example 5
     */
    readonly floorIndex?: number;
    /** Project slug */
    readonly projectSlug: string;
}>;

export type SearchListParams = Readonly<{
    /**
     * @format int64
     * @min 1
     * @example 1
     */
    readonly page: number;
    /**
     * @format int64
     * @min 1
     * @max 100
     * @example 1
     */
    readonly pageSize: number;
    /** Sort mode (price/area) parameter. */
    readonly sortMode: 'price' | 'area';
    /** Sort direction (ascending/descending) parameter. */
    readonly sortDirection: 'asc' | 'desc';
    /** Minimum apartment price (in russian kopecks) parameter. */
    readonly priceRangeMin?: Rubles;
    /** Maximum apartment price (in russian kopecks) parameter. */
    readonly priceRangeMax?: Rubles;
    /** Minimum total apartment area (in m²) parameter. */
    readonly areaRangeMin?: FiniteFloat64;
    /** Maximum total apartment area (in m²) parameter. */
    readonly areaRangeMax?: FiniteFloat64;
    /** Number of rooms multiselect parameter. */
    readonly roomsNumber?: RoomsMultiselectQueryParameter;
    /**
     * A locality of project multiselect parameter.
     * @example ["Sadgorod","Zarya"]
     */
    readonly locality?: LocalityMultiselectQueryParameter;
    /**
     * Project multiselect parameter.
     * @example [2,3]
     */
    readonly project?: MultiselectQueryParameter;
    /**
     * Completion year multiselect parameter.
     * @example [2022,2024]
     */
    readonly completionYear?: MultiselectQueryParameter;
    /**
     * Advantages multiselect parameter.
     * @example [4,2,1]
     */
    readonly advantages?: MultiselectQueryParameter;
}>;

export type CompareApartmentsListParams = Readonly<{
    /**
     * Apartment IDs array
     * @maxItems 8
     * @minItems 1
     * @example [4,2,1]
     */
    readonly apartmentIds: string[];
}>;

export type MortgageDetailParams = Readonly<{
    /** @example 5 */
    readonly initialPaymentPercent?: number;
    /** @example 5 */
    readonly termInYears?: number;
    /** Apartment slug */
    readonly apartmentSlug: string;
}>;
