import clsx from 'clsx';

import type { FC } from 'react';

import { useUnit } from 'effector-react';

import { setFeedbackDialogOpened } from '@stores/app';

import { GtmEventKind, sendGtmEvent } from '@utils/analytics';
import { applyTypograf } from '@utils/typograf';

import { Button } from '@components/common/Button/Button';
import { Container } from '@components/common/Container/Container';

import styles from './SubmitWidget.module.scss';

type SubmitWidgetProps = {
    className?: string;
};
export const SubmitWidget: FC<SubmitWidgetProps> = ({ className }) => {
    const setFeedbackDialogState = useUnit(setFeedbackDialogOpened);

    const openFeedbackDialog = () => {
        setFeedbackDialogState(true);
        sendGtmEvent({ kind: GtmEventKind.ConsultationButtonClicked });
    };

    return (
        <div className={clsx(styles.root, className)}>
            <Container classes={{ root: styles.container }}>
                <div className={styles.textBlock}>
                    <p className={styles.label}>
                        {applyTypograf('Узнайте обо всем, что вас интересует')}
                    </p>
                    <p className={styles.text}>
                        {applyTypograf('Оставьте свои контактные данные, и мы вам перезвоним')}
                    </p>
                </div>

                <Button variant="darkBlue" className={styles.button} onClick={openFeedbackDialog}>
                    Хочу консультацию
                </Button>
            </Container>
        </div>
    );
};
